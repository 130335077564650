.quantumBlock {
  .textDetail {
    max-width: 90%;
    text-align: center;
    margin: auto;
    @media (min-width: 768px) {
      text-align: justify;
      text-align-last: center;
    }
    .subTitle {
      font-size: 40px;
      max-width: fit-content;
      margin: auto;
      line-height: 1;
      color: #50759d;
      margin-bottom: 8px;
    }
    .aboutUsSmallHeading {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .PartnerCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    width: 220px;
    margin: 0 20px;
    .avatar {
      width: 90% !important;
      height: 100px;
      object-fit: contain;
    }
  }
}
