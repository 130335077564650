@import "src/assets/css/helpers/variables.scss";

.accreditedSection {
  padding: 50px 0;
  .containerBig {
    @media (min-width: 1440px) {
      max-width: 1660px;
    }
  }
  .accreditedWrap {
    border: 4px solid $brand-light;
    padding: 5px;
    .content {
      border: 2px solid $bg-gray;
      background-color: lighten($brand-light, 32%);
      padding: 30px;
      .sectionTitle {
        text-align: center;
        font-size: 50px;
        line-height: 56px;
        margin-bottom: 30px;
        font-weight: 900;
        font-family: "Segoe UI Bold";
      }
      h5 {
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      p {
        font-size: 22px;
        line-height: 28px;
      }
      img {
        max-width: 350px;
        width: 100%;
      }
    }
  }
}
