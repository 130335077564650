.aboutTcs {
  margin-bottom: 50px;
  .imgHeaderRight {
    display: none;
  }
  @media (min-width: 992px) {
    margin-bottom: 100px;
    .imgHeaderRight {
      display: block;
    }
  }
  .textDetail {
    max-width: 740px;
    text-align: center;
    margin: auto;
    @media (min-width: 768px) {
      text-align: justify;
      text-align-last: center;
    }
    .oneLiner {
      color: #346e8a;
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 60px;
      @media (min-width: 768px) {
        font-size: 40px;
      }
    }
    .content {
      font-size: 17px;
      text-align: justify;
      text-align-last: center;
    }
  }
}
