@import 'src/assets/css/helpers/variables.scss';

.testimonialSec {
  padding: 50px 0;

  .heading {
    font-size: 50px;
    font-weight: bold;
    color: $brand;

    @media (max-width: 991px) {
      font-size: 36px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 20px;
    }
  }

  .quoteIcon {
    display: flex;
    margin: 0 auto;
    opacity: 0.2;

    path {
      fill: #346e8a;
    }
  }

  .tabs {
    border: none;
    justify-content: center;
    gap: 20px;

    li {
      a {
        border: 1px solid #346e8a;
        border-radius: 30px;
        min-height: 60px;
        display: block;
        background: #fff;
        line-height: 40px;
        padding: 10px 15px;
        font-size: 20px;
        color: #346e8a;
        text-align: center;

        &[class~="active"] {
          border-color: transparent;
          background: #346e8a;
          color: #fff;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  .testimoniaSecBg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom 20%;
    padding-top: 20px;

    .tabContent {
      display: block;
      position: relative;

      div[class~='tab-pane'] {
        display: block;
        opacity: 0;
        visibility: hidden;
        height: 0;

        &[class~='active'] {
          opacity: 1;
          visibility: visible;
          height: auto;
        }
      }

      @media screen and (max-width: 991px) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 991px) {
      margin-top: 50px;
      background-size: contain;
      padding-top: 10rem;
      background-position: top center;
      padding-bottom: 5rem;
      padding-left: 15px;
      padding-right: 15px;
      min-height: auto;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 0rem;
      padding-top: 2rem;
    }
  }
}

.testimonials {
  padding: 0 0;

  div[class~='slick-slider'] {
    overflow: hidden;

    div[class~='slick-list'] {
      width: calc(100% - 144px);
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;

      div[class~='slick-track'] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .sliderContent {
          position: relative;

          p {
            font-size: 24px;
            line-height: 30px;
            color: #346e8a;
            text-align: center;
            max-width: 820px;
            margin: auto;

            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
          }

          h6 {
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #346e8a;
          }
        }
      }

      img {
        width: 100%;
        max-width: 120px;
        height: 120px;
        border-radius: 50%;
        display: block;
        margin: 0 auto 30px;
        object-fit: cover;
        object-position: center;
      }
    }

    div[class~='slick-arrow'] {
      z-index: 9999;
      width: 72px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &:before {
        color: $brand;
      }

      &[class~='slick-prev'] {
        left: 0;
      }

      &[class~='slick-next'] {
        right: 0;
      }
    }

    ul[class~='slick-dots'] {
      margin: 0;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 0 10px;

      @media (max-width: 991px) {
        display: none;
      }

      li {
        // @media (min-width: 1400px) {
        //   position: absolute !important;
        // }

        list-style-type: none;

        button {
          border: 0;
          outline: 0;
          background-color: transparent;
          font-size: 0;
          width: 10px;
          height: 10px;
          border-radius: 50px;
          background-color: $paragraph;
          padding: 0;
          transition: $transition;
          transform: scaleX(1);
        }

        &[class~='slick-active'] {
          button {
            background-image: linear-gradient(to right, #38c1cf, #5bcbb8);
            width: 30px;
          }
        }

        // img {
        //   object-fit: cover;
        //   object-position: top center;
        //   border-radius: 50%;
        //   filter: grayscale(1);
        //   transform: scale(1);
        //   position: relative;
        //   transition: 0.3s ease all;
        //   width: 40px;
        //   height: 40px;
        // }

        // &[class~='slick-active'] {
        //   button {
        //     img {
        //       filter: grayscale(0);
        //       transform: scale(1.4);
        //       z-index: 2;
        //     }
        //   }
        // }

        // @media (min-width: 1400px) {
        //   &:nth-child(1) {
        //     bottom: 5%;
        //     left: -30%;

        //     img {
        //       width: 110px;
        //       height: 110px;
        //     }
        //   }

        //   &:nth-child(2) {
        //     bottom: 50%;
        //     left: -20%;

        //     img {
        //       width: 130px;
        //       height: 130px;
        //     }
        //   }

        //   &:nth-child(3) {
        //     bottom: 90%;
        //     left: -35%;

        //     img {
        //       width: 130px;
        //       height: 130px;
        //     }
        //   }

        //   &:nth-child(4) {
        //     bottom: 120%;
        //     left: -20%;

        //     img {
        //       width: 100px;
        //       height: 100px;
        //     }
        //   }

        //   &:nth-child(5) {
        //     bottom: 100%;
        //     left: -8%;

        //     img {
        //       width: 120px;
        //       height: 120px;
        //     }
        //   }

        //   &:nth-child(6) {
        //     bottom: 130%;
        //     left: 5%;

        //     img {
        //       width: 130px;
        //       height: 130px;
        //     }
        //   }

        //   &:nth-child(7) {
        //     top: -90%;
        //     left: 20%;

        //     img {
        //       width: 100px;
        //       height: 100px;
        //     }
        //   }

        //   &:nth-child(8) {
        //     top: -90%;
        //     left: 35%;

        //     img {
        //       width: 95px;
        //       height: 95px;
        //     }
        //   }

        //   &:nth-child(9) {
        //     top: -90%;
        //     right: 35%;

        //     img {
        //       width: 130px;
        //       height: 130px;
        //     }
        //   }

        //   &:nth-child(10) {
        //     top: -90%;
        //     right: 20%;

        //     img {
        //       width: 90px;
        //       height: 90px;
        //     }
        //   }

        //   &:nth-child(11) {
        //     bottom: 100%;
        //     right: 0%;

        //     img {
        //       width: 160px;
        //       height: 160px;
        //     }
        //   }

        //   &:nth-child(12) {
        //     top: -80%;
        //     right: -20%;

        //     img {
        //       width: 160px;
        //       height: 160px;
        //     }
        //   }

        //   &:nth-child(13) {
        //     top: -10%;
        //     right: -15%;

        //     img {
        //       width: 110px;
        //       height: 110px;
        //     }
        //   }

        //   &:nth-child(14) {
        //     top: -10%;
        //     right: -30%;

        //     img {
        //       width: 100px;
        //       height: 100px;
        //     }
        //   }

        //   &:nth-child(15) {
        //     top: 50%;
        //     right: -25%;

        //     img {
        //       width: 90px;
        //       height: 90px;
        //     }
        //   }

        //   &:nth-child(16) {
        //     top: 50%;
        //     right: -15%;

        //     img {
        //       width: 160px;
        //       height: 160px;
        //     }
        //   }
        // }
      }
    }
  }
}