@import "src/assets/css/helpers/animation.scss";

.whoShouldJoin {
  padding: 50px 0;
  position: relative;
  @media (min-width: 992px) {
    padding: 100px 0;
  }
  @media (min-width: 1200px) {
    &:after {
      content: "";
      position: absolute;
      background-image: url("../../../assets/img/train/shape-1.png");
      background-size: 100% 100%;
      background-position: center;
      width: 200px;
      height: 200px;
      bottom: -100px;
      right: -100px;
      z-index: 1;
      animation: rotate 20s normal linear infinite;
      @media (min-width: 768px) {
        width: 300px;
        height: 300px;
        bottom: -150px;
        right: -150px;
      }
      @media (min-width: 992px) {
        width: 400px;
        height: 400px;
        bottom: -200px;
        right: -200px;
      }
    }
  }
  .heading {
    font-size: 32px;
    line-height: 38px;
    font-family: "seguibl";
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;
    @media (min-width: 992px) {
      font-size: 46px;
      line-height: 52px;
      margin-bottom: 50px;
    }
    .textThin {
      font-size: 28px;
      line-height: 34px;
      font-family: "Segoe UI Bold";
      @media (min-width: 992px) {
        font-size: 40px;
        line-height: 46px;
      }
    }
  }
  .headingContainer {
    display: inline-block;
    h4 {
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      text-align: center;
      margin-bottom: 0;
    }
  }
  p {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
    margin: 15px 0 0;
    position: relative;
    z-index: 9999;
    text-align: center;
    @media (min-width: 768px) {
      max-width: 80%;
      margin: 25px auto 25px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
