.banner {
  position: relative;
  z-index: 99;
  background-color: #f1f1f1;
  margin-top: -128px;
  padding-top: 128px;
  padding-bottom: 50px;
  &:after {
    position: absolute;
    content: "";
    right: auto;
    left: -395px;
    bottom: -560px;
    width: 680px;
    height: 758px;
    background: url("../../../assets//img/train/shape-1.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    -webkit-animation: rotate 20s normal linear infinite;
    animation: rotate 20s normal linear infinite;
    display: none;
    @media screen and (min-width: 992px) and (max-width: 1299px) {
      width: 500px;
      height: 500px;
      bottom: -400px;
      display: block;
    }
    @media screen and (min-width: 1300px) and (max-width: 1499px) {
      width: 400px;
      height: 400px;
      bottom: -320px;
      right: auto;
      left: -250px;
    }
    @media screen and (min-width: 1500px) and (max-width: 1599px) {
      width: 500px;
      height: 500px;
      bottom: -420px;
      left: -300px;
    }
    @media screen and (min-width: 1600px) and (max-width: 1700px) {
      bottom: -470px;
    }
  }
  .headline {
    h1 {
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      font-family: "seguibl" !important;
      text-transform: uppercase;
      margin: auto;
      @media (min-width: 576px) {
        font-size: 40px;
        line-height: 46px;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 50px;
        line-height: 60px;
      }
      @media (min-width: 1200px) and (max-width: 1440px) {
        font-size: 45px;
        line-height: 51px;
      }
      @media (min-width: 1441px) {
        font-size: 55px;
        line-height: 61px;
      }
    }
  }
  .desc {
    p {
      font-size: 20px;
      line-height: 30px;
      color: #a1a1a1;
      text-align: center;
      max-width: 80%;
      margin: 25px auto 25px;
      position: relative;
      z-index: 9999;
    }
  }
  .trainPageTagline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    text-align: center;
    img {
      max-width: 150px;
    }
    h4 {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 15px;
      max-width: 70%;
      @media (min-width: 992px) {
        font-size: 23px;
        line-height: 29px;
        margin-bottom: 0;
        margin-right: 15px;
      }
    }
  }
  .more {
    text-align: center;
    animation: crescendo 1.5s alternate infinite ease-in;
    a {
      background: url("../../../assets/img/train/more-bg.png") no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 44px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: auto;
      i {
        background: rgb(0, 93, 146);
        background: radial-gradient(
          circle,
          rgba(0, 93, 146, 1) 0%,
          rgba(56, 182, 255, 1) 78%
        );
        color: #fff;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 15px;
        font-weight: 100;
      }
    }
  }
}

.headline {
  img {
    max-width: 582px;
  }
  .trainLogo {
    font-size: 90px;
    @media (max-width: 1199px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (max-width: 575px) {
      font-size: 30px;
      line-height: 36px;
    }
    .tlGrayDark {
      color: #6d6d6d;
    }
    .tlGrayLight {
      color: #c5c8cb;
    }
  }
}
