.aboutBlockImage {
  background-size: cover;
  width: 100vw;
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;
  h1 {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-family: "Poppins";
    font-weight: bold;
    color: #356e8b;
    font-size: 40px;
    @media (min-width: 992px) {
      font-size: 60px;
    }
    @media (min-width: 1200px) {
      font-size: 100px;
    }
  }
}
