@import 'src/assets/css/helpers/variables.scss';

.newsPage {
  .newsBanner {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 30px;
  }
  .newsTitle {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .newsSubtitle {
    text-align: center;
    color: $paragraph;
    margin-bottom: 30px;
    font-size: 20px;
  }
  .smallTitle {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 10px 0 30px;
  }
  p {
    font-size: 16px;
  }
  p:not(:last-child) {
    margin-bottom: 20px;
  }
  .infoBox {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      a {
        color: $brand;
      }
    }
  }
  .link-text {
    color: #38c1cf;
  }
}
