@import "src/assets/css/helpers/animation.scss";

.partnersCollaborators {
  padding: 50px 0;
  overflow: hidden !important;
  .tagline {
    h3 {
      color: #000000;
      font-family: "Segoe UI Bold";
      text-align: center;
      font-size: 25px;
      line-height: 35px;
      @media (min-width: 576px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media (min-width: 768px) {
        text-align: left;
      }
      @media (min-width: 992px) {
        font-size: 50px;
        line-height: 60px;
      }
      span {
        background: #005d92;
        background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .desc {
      p {
        font-size: 16px;
        line-height: 23px;
        color: #818181;
        text-align: center;
        @media (min-width: 768px) {
          text-align: left;
        }
      }
    }
    .img {
      text-align: center;
      margin: 40px auto 0;
      img {
        width: 100%;
      }
    }
  }
  .sponsor {
    li:nth-child(2) img {
      opacity: 0.5;
    }
  }
  .partners {
    padding-left: 0;
    margin-bottom: 40px;
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
    &:first-child {
      li {
        img {
          max-width: 200px;
        }
      }
    }
    li {
      &:not(:last-child) {
        margin-right: 30px;
      }
      img {
        max-width: 235px;
        width: 100%;
        height: auto;
      }
    }
  }
  .pic {
    img {
      -webkit-animation: rotate 20s normal linear infinite;
      animation: rotate 20s normal linear infinite;
      width: 100%;
      @media (min-width: 768px) {
        width: auto;
      }
    }
  }
  .row {
    align-items: center;
  }
  ul.sponsor {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 50px;
      &:nth-child(2) {
        position: relative;
      }
    }
  }
}
