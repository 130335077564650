div[class~="slick-prev"] {
  width: 42px;
  height: 42px;
  z-index: 1;
  &:before {
    content: none;
  }
  .arrow {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 30px;
    cursor: pointer;
    background: #38c1cf;
    background: linear-gradient(to right, #38c1cf, #5bcbb8);
    &:before {
      content: "";
      display: block;
      width: 10px;
      flex: 0 0 10px;
      height: 10px;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(45deg);
    }
    &:after {
      content: "";
      display: block;
      width: 0px;
      flex: 0 0 0px;
      height: 1px;
      background-color: #fff;
      transition: all 0.5s ease-out;
    }
    &:hover {
      cursor: pointer;
      &:after {
        width: 30px;
        flex: 0 0 30px;
      }
    }
  }
}
