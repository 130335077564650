@import "src/assets/css/helpers/animation.scss";

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

.trainCourseBanner {
  background-color: #f1f1f1;
  margin-top: -128px;
  padding-top: 150px;
  padding-bottom: 50px;

  h2 {
    font-size: 62px;
    line-height: 68px;
    font-family: "seguibl" !important;
    text-transform: uppercase;
    margin: 0 auto 15px;

    @media (max-width: 1199px) {
      &>span:first-child {
        display: block;
      }
    }

    @media (max-width: 575px) {
      font-size: 28px;
      line-height: 34px;
    }

    .smallSubtitle {
      font-size: 42px;
      line-height: 48px;
    }
  }

  .secondSubTitle {
    font-size: 22px;
    margin-bottom: 15px;
    font-family: "seguibl" !important;

    @media (min-width: 992px) {
      font-size: 28px;
    }

    img {
      max-width: 100px;
      width: 100%;
    }
  }

  .secondSubTitle {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 400;
    font-family: "seguibl" !important;

    @media (min-width: 992px) {
      font-size: 28px;
    }
  }

  .thirdSubTitle {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: "Segoe UI Regular" !important;

    @media (min-width: 992px) {
      font-size: 20px;
    }
  }

  .train-logo-wrap {
    .train-logo {
      font-size: 90px;
      font-family: "seguibl" !important;

      @media (max-width: 1199px) {
        font-size: 50px;
        line-height: 60px;
      }

      @media (max-width: 575px) {
        font-size: 30px;
        line-height: 36px;
      }

      .tl-gray-dark {
        color: #6d6d6d;
      }

      .tl-gray-light {
        color: #c5c8cb;
      }
    }
  }

  .button {
    font-size: 28px;
    padding: 10px 50px;
    margin-bottom: 30px;
  }

  .player {
    [class~="lf-player-container"] {
      max-width: 120px;
      width: 100%;
      margin: auto;
    }
  }
}