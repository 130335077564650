.trainPdApplication {
  margin: 50px 0;
  .iframeContainer {
    iframe {
      border: 0;
      width: 100%;
      min-height: 1950px;
    }
  }
}
