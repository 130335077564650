.courseSupport {
  padding: 30px 0;
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media (min-width: 992px) {
      text-align: left;
    }
    span {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
      @media (min-width: 992px) {
        font-size: 24px;
      }
    }
    img {
      max-width: 200px;
      width: 100%;
      height: auto;
      @media (min-width: 992px) {
        max-width: 300px;
      }
    }
  }
}
