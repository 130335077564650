@keyframes crescendo {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes boxShadpwRipple {
  0% {
    box-shadow: 0 4px 10px rgba(91, 203, 184, 0.2),
      0 0 0 0 rgba(91, 203, 184, 0.2), 0 0 0 5px rgba(91, 203, 184, 0.2),
      0 0 0 10px rgba(91, 203, 184, 0.2);
  }
  100% {
    box-shadow: 0 4px 10px rgba(91, 203, 184, 0.2),
      0 0 0 5px rgba(91, 203, 184, 0.2), 0 0 0 10px rgba(91, 203, 184, 0.2),
      0 0 0 20px rgba(91, 203, 184, 0);
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes boxShadpwR {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes bgGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
