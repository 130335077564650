.visualText {
  box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
  padding: 30px;
  h4 {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
    @media screen and (max-width: 768px) {
      font-size: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  }
  h3 {
    @media screen and (max-width: 767px) {
      font-size: 30px !important;
      text-align: center;
    }
  }
  p {
    font-size: 20px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .home-up-title {
    font-size: 30px;
  }
}

span {
  &.highlight-underline {
    position: relative;
    display: inline-block;
    font-style: italic;
    &:before {
      background-color: #d1a74c;
      content: "";
      width: 100%;
      bottom: 10px;
      left: 4px;
      position: absolute;
      right: 0;
      height: 34%;
      display: block;
      z-index: -1;
    }
  }
  &.highlight-text {
    position: relative;
    font-style: italic;
    color: #d1a74c;
  }
}
