@import 'src/assets/css/helpers/variables.scss';

.upcomingPrograms {
  .brownSec {
    background-color: $gold;
    padding: 15px 15px 30px;
    position: relative;

    &:after {
      content: '';
      display: block;
      margin: auto;
      border-top: 20px solid $gold;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      width: 40px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
    }

    h2 {
      display: block;
      margin: 0 auto;
      font-size: 40px;
      line-height: 46px;
      font-weight: bold;
      color: #fff;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 30px !important;
      }
    }
  }

  .upcomingProgramsTab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style-type: none;

    .tabTitle {
      flex: 1 0 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid;
      padding: 10px 15px;
      border-radius: 50px;
      transition: $transition;

      @media (min-width: 992px) {
        flex: 1;
      }

      &:not(:last-child) {
        margin-bottom: 15px;

        @media (min-width: 992px) {
          margin-bottom: 0;
          margin-right: 15px;
        }
      }

      .titleContent {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
        transition: $transition;

        @media (min-width: 992px) {
          font-size: 18px;
        }

        @media (min-width: 1200px) {
          font-size: 22px;
        }
      }

      &:hover,
      &:global(.active) {
        border-color: $gold;

        .titleContent {
          color: $gold;
        }
      }
    }
  }

  .programPostCarousel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &.centeredCarousel {
      justify-content: center;

      div[class~='slick-list'] {
        display: flex;

        @media(min-width: 768px) {
          justify-content: center;
        }
      }
    }

    div[class~='slick-arrow'] {
      position: relative;
      width: auto;
    }

    div[class~='slick-prev'] {
      order: 1;
      left: 0;
    }

    div[class~='slick-next'] {
      order: 3;
      right: 0;
    }

    div[class~='slick-list'] {
      width: calc(100% - 144px);
      order: 2;
      overflow: hidden;

      @media (min-width: 768px) {
        margin: 0 -15px;
      }

      div[class~='slick-track'] {
        display: flex;

        div[class~='slick-slide'] {
          margin: 0 15px;

          &>div {
            height: 100%;
          }
        }
      }
    }
  }

  .programPostItem {
    background: #fff;
    box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #dedddd;
    height: 100%;

    .programPostImg {
      height: 300px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }

    .programPostDesc {
      padding: 15px;
      display: flex;
      flex-flow: column;
      height: calc(100% - 300px);

      h4 {
        color: #151515;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;

        @media (min-width: 1200px) and (max-width: 1440px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      h5 {
        color: #151515;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;

        @media (min-width: 1200px) and (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        min-height: 99px;

        li {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #151515;
          display: flex;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &:before {
            content: '\2713';
            margin-right: 5px;
          }
        }
      }

      .button {
        align-self: center;
        max-width: 190px;
        width: 100%;
      }
    }

    .programsButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: auto;
      gap: 15px;

      .button {
        @media (max-width: 991px) {
          flex: 0 0 60%;
          max-width: initial;

          &:nth-child(2) {
            margin-left: 0;
            margin-top: 15px;
          }
        }
      }
    }

    &.nonCarousel {
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      .programPostImg {
        @media (min-width: 768px) {
          max-width: 300px;
          width: 100%;
          height: auto;
        }

        @media (max-width: 991px) {
          margin-bottom: 0;
        }
      }

      .programPostDesc {
        @media (min-width: 768px) {
          width: calc(100% - 300px);
        }

        h4 {
          @media (min-width: 768px) {
            text-align: left;
          }

          @media (min-width: 992px) {
            font-size: 24px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .slick-arrow {
    z-index: 999999;
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease all;

    &:before {
      opacity: 1;
      font-size: 40px;
      color: $brand;
      background: linear-gradient(to right, #38c1cf, #5bcbb8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.slick-prev {
      left: 25px;
    }

    &.slick-next {
      right: 25px;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease all;
    }
  }
}