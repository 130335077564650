@import 'src/assets/css/helpers/variables.scss';
@import 'src/assets/css/helpers/animation.scss';

.courseBenefitsSection {
  background-color: $bg-gray;
  padding: 50px 0;
  .containerBig {
    @media (min-width: 1440px) {
      max-width: 1660px;
    }
  }
  .sectionTitle {
    text-align: center;
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 80px;
    font-weight: 900;
    font-family: 'Segoe UI Bold';
  }
  .desc {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .boldDesc {
    margin-bottom: 30px;
    font-size: unquote('clamp(1rem, 0.75rem + 1.25vw, 1.5rem)');
    font-weight: 500;
  }
  .psText {
    margin-top: 30px;
    font-size: unquote('clamp(0.9rem, 0.75rem + 1.25vw, 1.2rem)');
    font-weight: 600;
  }
  .courseComponents {
    list-style-type: none;
    margin-bottom: 0;
    li {
      font-size: 18px;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:before {
        content: url('/assets/img/common/icon-atom.png');
        animation: rotate 4s linear infinite;
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .bannerButtons {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    li {
      max-width: 270px;
      width: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid $brand;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        padding: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        min-height: 81px;
        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          position: relative;
          z-index: 2;
          color: $brand;
          transition: $transition;
          span {
            display: block;
            font-size: 14px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: -800px;
          left: -850px;
          width: 1000px;
          height: 1000px;
          border-radius: 100%;
          transition: $transition;
          background: linear-gradient(
            94deg,
            rgba(52, 110, 138, 1) 0%,
            rgba(122, 205, 246, 1) 100%
          );
        }
        &:hover {
          color: #fff;
          &:hover {
            span {
              color: #fff;
            }
            &:before {
              top: -650px;
              left: -400px;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
