@import "src/assets/css/helpers/variables.scss";

.containerBig {
  @media (min-width: 1440px) {
    max-width: 1660px;
  }
}

.emergingCourse {
  padding: 50px 0;

  header {
    text-align: center;
    margin-bottom: 50px;

    .sectionTitle {
      font-size: 50px;
      line-height: 56px;
      margin-bottom: 30px;
      font-weight: 900;
      font-family: "Segoe UI Bold";
    }

    .sectionDescription {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .cardArea {
    padding: 50px 0;
    background-color: $bg-gray;

    div[class~="col-12"] {
      &:not(:last-child) {
        margin-bottom: 30px;

        @media (min-width: 992px) {
          margin-bottom: 0;
        }
      }
    }

    .coursesTabs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .tabTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid $brand;
        border-radius: 8px;
        background-color: #fff;
        padding: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        min-height: 81px;
        max-width: 235px;
        width: 100%;
        transition: $transition;
        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          position: relative;
          z-index: 2;
          color: $brand;
          transition: $transition;

          span {
            display: block;
            font-size: 14px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: -800px;
          left: -850px;
          width: 1000px;
          height: 1000px;
          border-radius: 100%;
          transition: $transition;
          background: linear-gradient(94deg,
              rgba(52, 110, 138, 1) 0%,
              rgba(122, 205, 246, 1) 100%);
        }

        &:hover,
        &:global(.active) {
          color: #fff;
          span {
            color: #fff;
          }

          &:before {
            top: -650px;
            left: -400px;
            border-radius: 0;
          }
        }
        &:global(.active) {
          border-color: $gold;
        }
      }
    }

    .cardWrap {
      box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
      border-radius: 8px;
      overflow: hidden;
      height: 100%;

      .imgWrap {
        max-width: 100%;
        height: 300px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .cardTitle {
          text-transform: uppercase;
          color: $white;
          background-color: transparentize(#000, 0.3);
          padding: 10px;
          position: absolute;
          top: 0;
          margin-bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }

      .contentWrap {
        padding: 30px;
        height: calc(100% - 300px);
        display: flex;
        flex-flow: column wrap;

        .cardSubtitle {
          font-size: 18px;
          font-weight: 500;

          span {
            font-size: 14px;
          }
        }

        .keyPoints {
          list-style-type: none;
          margin-bottom: 30px;

          li {
            font-size: 16px;
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            &:before {
              content: "";
              background-image: url("/assets/img/common/icon-atom.png");
              background-repeat: no-repeat;
              background-size: 100%;
              margin-right: 10px;
              width: 20px;
              height: 20px;
            }

            span {
              width: calc(100% - 30px);
            }
          }
        }

        .buttons {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 0 15px;
          height: 36px;
          margin-top: auto;

          .button {
            max-width: 140px;
            width: 100%;
          }
        }
      }
    }
  }
}