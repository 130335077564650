.diverseWrapper {
  position: relative;
  height: 170px;
//   background-attachment: fixed;
//   background-size: cover;
//   background-position: center;
overflow: hidden;
img {
    position: relative;
    top: -513px
}
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(52, 110, 138, 0.651);
    z-index: 2;
  }
}
