// ----------- Helpers ----------- //

@import './helpers/variables.scss';
@import './helpers/fonts.scss';
@import './helpers/font-size.scss';
@import './helpers/spacing.scss';
@import './helpers/opacity.scss';
@import './helpers/border-radius.scss';
@import './helpers/color-scheme.scss';

@import './helpers/animate.scss';
@import './helpers/aos.scss';

@import 'bootstrap.scss';

@keyframes highlight {
  0% {
    width: 0%;
  }

  100% {
    width: 90%;
  }
}

.w-fit {
  width: fit-content;
}

.cp {
  cursor: pointer;
}

button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.center {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.text- {
  &gold {
    color: $gold !important;
  }

  &brand-light {
    color: $brand-light;
  }

  &black {
    color: #000;
  }

  &brand {
    color: $brand !important;
  }

  &thin {
    font-weight: 300 !important;
  }

  &500 {
    font-weight: 500;
  }

  &bold {
    font-weight: bold !important;
  }

  &bolder {
    font-weight: 900 !important;
  }

  &italic {
    font-style: italic !important;
  }

  &underline {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  &upper {
    text-transform: uppercase !important;
  }
  
  &lower {
    text-transform: lowercase !important;
  }

  &blue-gradient {
    background: #005d92;
    background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.self-underlined {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
      }
    }
  }

  &blue-gradient-2 {
    background: #005d92;
    background: linear-gradient(to right, #38c1cf, #5bcbb8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.self-underlined {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #38c1cf, #5bcbb8);
      }
    }
  }

  &blue-gold-gradient {
    background: $brand;
    background: linear-gradient(to right, $brand, $gold);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.self-underlined {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, $brand, $gold);
    }
  }

  &animated {
    position: relative;

    span {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      height: 50%;
      bottom: -5px;
      left: -5px;
      background-color: $gold;
      animation: 1.25s highlight infinite alternate;
      opacity: 0.7;
    }
  }
}

.btn {
  border: 0;

  &.btn-primary {
    background-color: $brand;
  }

  &.btn-primary-gradient {
    background-color: $brand;
    background-image: linear-gradient(94deg,
        rgba(52, 110, 138, 1) 0%,
        rgba(122, 205, 246, 1) 100%);
    background-size: 200%;
    background-position: 0 0;
    transition: $transition;

    &:hover {
      background-position: 50% 0;
    }
  }

  &.btn-gradient-2 {
    background-color: #38c1cf;
    background-image: linear-gradient(to right, #38c1cf, #5bcbb8);
    background-size: 200%;
    background-position: 0 0;
    transition: $transition;

    &:hover {
      background-position: 100% 0;
    }
  }

  &.gold-gradient {
    background-color: $goldTwo;
    background-image: linear-gradient(94deg,
        $gold 0%,
        $goldTwo 100%);
    background-size: 200%;
    background-position: 0 0;
    transition: $transition;

    &:hover {
      background-position: 50% 0;
    }
  }

  &.btn-outline {
    background-color: transparent;
    border: 2px solid $brand;
    color: $brand;
  }

  &.btn-brand {
    background-color: $brand;
    color: #fff;
  }
}

span {
  &.highlight-underline {
    position: relative;
    display: inline-block;
    font-style: italic;

    &:before {
      background-color: $gold;
      content: '';
      width: 100%;
      bottom: 10px;
      left: 4px;
      position: absolute;
      right: 0;
      height: 34%;
      display: block;
      z-index: -1;
    }
  }

  &.highlight-text {
    position: relative;
    font-style: italic;
    color: $gold;
  }
}

.bg- {
  &blue-gradient {
    background: linear-gradient(to right, #005d92 0%, #38b6ff 78%) !important;
  }

  &gradient-2 {
    background: linear-gradient(to right, #38c1cf 0%, #5bcbb8 78%) !important;
  }

  &brand {
    background-color: $brand !important;
  }

  &gray {
    background-color: $bg-gray;
  }

  &gray-2 {
    background-color: $gray-2;
  }

  &light-red {
    background-color: $light-red;
  }
}

.shadowedBox {
  box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
  padding: 30px;
}

.img-highlighted {
  position: relative;

  img {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $gold;
    top: -5%;
  }

  &.right {
    &:before {
      right: -5%;
    }
  }

  &.left {
    &:before {
      left: -5%;
    }
  }
}

.formField {
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
  }

  input {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    outline: 0;
    transition: $transition;

    &:focus {
      border-color: $brand-light;
    }
  }
}

.zi-1 {
  z-index: 1;
}

.font- {
  &seg-ui-slim {font-family: "segoeuisl";}
  &seg-ui {font-family: "SEGOEUI";}
  &seg-ui-reg {font-family: "Segoe UI Regular";}
  &seg-ui-bold {font-family: "Segoe UI Bold";}
  &seg-ui-black {font-family: "seguibl";}
}

.invert-img {
  filter: brightness(0) invert(1);
}