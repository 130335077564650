@import "src/assets/css/helpers/variables.scss";
@import "src/assets/css/helpers/animation.scss";

.trainOfferings {
  animation: bgGradient 15s ease infinite;
  background: linear-gradient(-45deg, #005d92, #38b6ff, #38c1cf, #5bcbb8);
  background-size: 400% 400%;
  padding: 30px 0;
  &.isNarrow {
    padding: 20px 0;
  }

  .wrap {
    .offeringsRow {
      gap: 30px 0;
      .item {
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        @media (min-width: 768px) {
          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 15px;
          }
        }

        @media (min-width: 992px) {

          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 0;
          }
        }

        .content {
          text-align: center;

          img {
            margin-bottom: 10px;
            max-width: 60px;
            width: 100%;
          }

          .title {
            color: $white;
            font-size: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}