@import 'src/assets/css/helpers/variables.scss';

.engBg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 60px 0px #000;

  .containerBig {
    max-width: 1660px;

    h3 {
      color: #fff;
      font-size: 40px;
      line-height: 46px;
      font-weight: bold;
      margin-bottom: 30px;

      @media screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 40px;
      }

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 36px;
        margin-top: 40px;
      }
    }

    p {
      font-size: 18px;
      color: #fff;
      line-height: 24px;
      margin-bottom: 0;

      @media (min-width: 992px) and (max-width: 1199px) {
        min-height: 96px;
      }

      @media (min-width: 1200px) and (max-width: 1440px) {
        min-height: 72px;
      }
    }

    img {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 60vh;
      display: block;
      margin: auto;

      @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 400px;
        margin: 0 auto;
        display: block;
      }
    }

    .engBgBottom {
      h5 {
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
        color: $brand;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        margin: 0 auto 10px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
}