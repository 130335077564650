@import 'src/assets/css/helpers/variables.scss';

.testimonial {
  box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 30px 50px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $brand;
    opacity: 0.65;
  }

  .contentArea {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;

    &:before {
      content: '"';
      font-size: 60px;
      line-height: 60px;
      max-width: 20px;
      width: 100%;
      margin-right: 15px;
      color: #fff;

      @media (min-width: 768px) {
        font-size: 100px;
        line-height: 70px;
        max-width: 50px;
      }
    }

    .wrap {
      flex: 0 0 calc(100% - 35px);
      color: #fff;
      font-weight: 600;

      @media (min-width: 768px) {
        flex: 0 0 calc(100% - 65px);
        font-weight: 700;
      }

      .quote {
        position: relative;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 30px;
        color: #fff;
      }

      .testifierImg {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        overflow: hidden;
        border: 3px solid $gold;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
        }
      }

      .testifierArea {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .separator {
        font-weight: 800;
        margin: 0 15px;
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }

      .quoteBy {
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 0;
        margin-top: 15px;
        @media (min-width: 768px) {
          margin-top: 0;
          width: calc(100% - 143px);
        }
      }
    }
  }
}

.testimonialSideWays {
  box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 30px 50px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $brand;
    opacity: 0.65;
  }

  .contentArea {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;

    &:before {
      content: '"';
      font-size: 60px;
      line-height: 60px;
      max-width: 20px;
      width: 100%;
      margin-right: 15px;
      color: #fff;

      @media (min-width: 768px) {
        font-size: 100px;
        line-height: 70px;
        max-width: 50px;
      }
    }

    .wrap {
      flex: 0 0 calc(100% - 265px);
      color: #fff;
      font-weight: 600;

      @media (min-width: 768px) {
        flex: 0 0 calc(100% - 295px);
        font-weight: 700;
      }

      .quote {
        position: relative;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 30px;
        color: #fff;
      }

      .testifierArea {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .separator {
        font-weight: 800;
        margin: 0 15px 0 0;
      }

      .quoteBy {
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 0;
        width: calc(100% - 43px);
      }
    }

    .testifierImg {
      width: 200px;
      height: 200px;
      border-radius: 100px;
      overflow: hidden;
      border: 3px solid $gold;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }
  }
}