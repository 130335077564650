@import 'src/assets/css/helpers/variables.scss';

.KeyInitiatives {
  .containerBig {
    max-width: 1600px;

    h3 {
      font-size: 50px;
      font-weight: bold;
      color: $brand;
    }

    .postBox {
      box-shadow: 0 0 25px -2px #dedede;
      background: #fff;

      .postBoxWrap {
        padding: 30px;
        border: 1px solid #ace1fb;
        text-align: center;

        img {
          max-width: 220px;
          width: 100%;
          display: block;
          margin: 0 auto 30px;
        }

        .title {
          font-size: 24px;
          line-height: 30px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        .desc {
          display: block;
          text-align: center;
          color: $brand;
          font-size: 18px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 30px;

          @media (min-width: 992px) {
            min-height: 235px;
          }

          @media (min-width: 1051px) {
            min-height: 208px;
          }

          @media (min-width: 1170px) {
            min-height: 182px;
          }

          @media (min-width: 1307px) {
            min-height: 156px;
          }

          @media (min-width: 1361px) {
            min-height: 130px;
          }

          @media (min-width: 1620px) {
            min-height: unset;
          }
        }

        .link {
          color: #346e8a;
          display: block;
          margin: 0 auto;
          text-align: center;
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 20px;
        }
      }
    }

    .rounded-border {
      display: inline-flex;
      min-width: 145px;
      height: 145px;
      align-items: center;
      padding: 20px;
      border: 4px solid;
      border-radius: 50%;
    }
  }
}