$animate-time: 6s;
$face-animate-function: ease-in-out;
$face-animate-iteration: infinite;
$animate-words: 6;

@keyframes cubeRotate {
  0% {
    transform: rotateX(-36deg) rotateY(45deg);
  }
  15% {
    transform: rotateX(-36deg) rotateY(225deg);
  }
  85% {
    transform: rotateX(-36deg) rotateY(225deg);
  }
  100% {
    transform: rotateX(-36deg) rotateY(405deg);
  }
}

@keyframes travel-up {
  0% {
    transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  20% {
    transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  50% {
    transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(25em);
  }
  80% {
    transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  100% {
    transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
}

@keyframes travel-down {
  0% {
    transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  20% {
    transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  50% {
    transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(25em);
  }
  80% {
    transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
  100% {
    transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(10em);
  }
}

@keyframes travel-left {
  0% {
    transform: rotateY(-90deg) translateZ(10em);
  }
  20% {
    transform: rotateY(-90deg) translateZ(10em);
  }
  50% {
    transform: rotateY(-90deg) translateZ(25em);
  }
  80% {
    transform: rotateY(-90deg) translateZ(10em);
  }
  100% {
    transform: rotateY(-90deg) translateZ(10em);
  }
}

@keyframes travel-right {
  0% {
    transform: rotateY(90deg) translateZ(10em);
  }
  20% {
    transform: rotateY(90deg) translateZ(10em);
  }
  50% {
    transform: rotateY(90deg) translateZ(25em);
  }
  80% {
    transform: rotateY(90deg) translateZ(10em);
  }
  100% {
    transform: rotateY(90deg) translateZ(10em);
  }
}

@keyframes travel-front {
  0% {
    transform: translateZ(10em);
  }
  20% {
    transform: translateZ(10em);
  }
  50% {
    transform: translateZ(25em);
  }
  80% {
    transform: translateZ(10em);
  }
  100% {
    transform: translateZ(10em);
  }
}

@keyframes travel-back {
  0% {
    transform: translateZ(-10em) rotateY(180deg);
  }
  20% {
    transform: translateZ(-10em) rotateY(180deg);
  }
  50% {
    transform: translateZ(-25em) rotateY(180deg);
  }
  80% {
    transform: translateZ(-10em) rotateY(180deg);
  }
  100% {
    transform: translateZ(-10em) rotateY(180deg);
  }
}

@keyframes wordToggle {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  55% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.scene {
  perspective: 10000px;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.wordsWrap {
  .word {
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: uppercase;
    text-align: center;
    font-size: 34px;
    font-weight: 800;
    animation-name: wordToggle;
    animation-duration: 1s * $animate-words;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    color: #fff;
    transform: translate(-30%, -50%);
    max-width: 250px;
    width: 100%;
  }
}
.cube {
  font-size: 10px;
  width: 10em;
  height: 10em;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-36deg) rotateY(45deg);
  transform-origin: right top;
  animation: cubeRotate $animate-time infinite linear;
}
.cube__face {
  position: absolute;
  width: 20em;
  height: 20em;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid #fff;
  border-radius: 5px;
}
.cube__face--top {
  transform: rotateX(90deg) translateY(-0px) translateX(0) translateZ(10em);
  animation: travel-up $animate-time $face-animate-function
    $face-animate-iteration;
}
.cube__face--bottom {
  transform: rotateX(-90deg) translateY(-0px) translateX(0) translateZ(10em);
  animation: travel-down $animate-time $face-animate-function
    $face-animate-iteration;
}
.cube__face--left {
  transform: rotateY(-90deg) translateZ(10em);
  animation: travel-left $animate-time $face-animate-function
    $face-animate-iteration;
}
.cube__face--right {
  transform: rotateY(90deg) translateZ(10em);
  animation: travel-right $animate-time $face-animate-function
    $face-animate-iteration;
}
.cube__face--front {
  transform: translateZ(10em);
  animation: travel-front $animate-time $face-animate-function
    $face-animate-iteration;
}
.cube__face--back {
  transform: translateZ(-10em) rotateY(180deg);
  animation: travel-back $animate-time $face-animate-function
    $face-animate-iteration;
}
