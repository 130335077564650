@import "src/assets/css/helpers/animation.scss";

.coursesOverview {
  background: url("../../../assets/img/train/ai-bg.jpg") no-repeat center/cover;
  position: relative;
  padding: 50px 0;
  @media (min-width: 992px) {
    padding: 100px 0;
  }
  .heading {
    font-size: 32px;
    line-height: 38px;
    font-family: "seguibl";
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
    position: relative;
    z-index: 2;
    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 46px;
    }
    @media (min-width: 992px) {
      font-size: 55px;
      line-height: 61px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
    opacity: 0.9;
  }
  .coTabs {
    position: relative;
    z-index: 2;
    .cotLinks {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      a {
        &[class~="active"] {
          .cotWrap {
            &:after {
              right: 0;
            }
          }
        }
        .cotWrap {
          display: flex;
          position: relative;
          background-color: #fff;
          border-radius: 4px;
          padding: 20px 30px;
          width: 100%;
          z-index: 1;
          overflow: hidden;
          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: -100%;
            background: linear-gradient(to right, #38c1cf, #5bcbb8);
            transition: 0.3s ease all;
          }
          .tabTitle {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;
            margin-bottom: 0;
            .numbering {
              position: relative;
              z-index: 2;
              width: 40px;
              height: 40px;
              margin-right: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
              color: #fff;
              &:before,
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid #005d92;
                border-radius: 50px;
              }
              &:before {
                animation: ripple 2s linear infinite;
              }
              &:after {
                animation: ripple 2s linear 1s infinite;
              }
            }
          }
        }
      }
    }
    .tabContent {
      background-color: #fff;
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 4px;
      text-align: center;
      padding: 30px;
      @media (min-width: 992px) {
        text-align: left;
        padding: 50px;
      }
      .featuresDesc {
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        .icon {
          display: flex;
          margin-right: 10px;
          margin-top: 6px;
          background: #005d92;
          background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
