@import "assets/css/helpers/variables.scss";

.footer {
  color: $white;
  .footerTop {
    background: #346e8a;
    padding: 30px 15px;
    a {
      color: $white;
    }
    .footerLogo {
      max-width: 150px;
      width: 100%;
      @media (min-width: 768px) {
        width: 14%;
      }
    }
    .subscriberForm {
      width: 100%;
      margin-top: 30px;
      @media (min-width: 768px) {
        width: 75%;
        margin-left: 40px;
        margin-top: 0;
      }
    }
  }
  .footerBottom {
    background: #23495c;
    padding: 15px 0;
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
  .socialShare {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      vertical-align: middle;
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
        font-size: 18px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        color: $brand;
        border-radius: 50px;
        transition: $transition;
        &:hover {
          color: $gold;
        }
      }
    }
  }

  .subscribeBtn {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    form {
      margin: 0 !important;
      padding: 0 !important;
      & > div:nth-child(1),
      & > div:nth-child(2) {
        display: none;
      }
      div[class~="fd-form-content"] {
        justify-content: flex-start;
      }
    }
  }
  .subscriber-input {
    position: relative;
    width: 100%;
    display: flex;
    input {
      border-radius: 0;
      background: #3f3828;
      border-color: #816830;
      color: white;
      &::placeholder {
        color: white;
      }
    }
    button {
      background: #ad8736;
      position: absolute;
      right: 3px;
      bottom: 3px;
      padding: 0.374rem 0.74rem;
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .footerCta {
    li {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      a {
        display: flex;
        transition: $transition;
        i {
          font-size: 18px;
          margin-right: 10px;
        }
        &:hover {
          color: $gold;
        }
      }
    }
  }
}
