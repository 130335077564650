@import "src/assets/css/helpers/variables.scss";
@import "src/assets/css/helpers/animation.scss";

.stepsToApply {
  padding: 50px 0;

  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
  }

  .steps {
    gap: 30px 0;

    .wrap {
      padding: 15px 30px;
      border-radius: 8px;
      box-shadow: 0px 1px 6px 2px transparentize($brand-light, 0.7);
      position: relative;
      height: 100%;

      @media (min-width: 768px) {
        padding: 30px;
      }

      .content {
        margin-bottom: 0;
      }

      .count {
        background: linear-gradient(to right,
            #38c1cf 0%,
            #5bcbb8 78%) !important;
        color: $white;
        font-size: 30px;
        font-weight: 700;
        width: 45px;
        height: 45px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: boxShadpwRipple 1s linear infinite;
        overflow: hidden;
        box-sizing: border-box;
        margin-bottom: 10px;

        @media (min-width: 768px) {
          position: absolute;
          top: -15px;
          left: -10px;
          margin-bottom: 0;
        }
      }
    }
  }
}