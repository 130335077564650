@import 'src/assets/css/helpers/variables.scss';

.ourPartnersBlock {
  .textDetail {
    max-width: 90%;
    text-align: center;
    margin: auto;

    @media (min-width: 768px) {
      text-align: justify;
      text-align-last: center;
    }

    .subTitle {
      font-size: 40px;
      max-width: fit-content;
      margin: auto;
      line-height: 1;
      color: #50759d;
      margin-bottom: 8px;
    }

    .aboutUsSmallHeading {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .sponsorLogosWrap {
    .sponsorLogos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      place-items: center;
      gap: 30px;

      @media (min-width: 576px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 992px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (min-width: 1400px) {
        grid-template-columns: repeat(8, 1fr);
      }

      .avatar {

        &[class~="aws"],
        &[class~="stemPush"],
        &[class~="nycEdc"],
        &[class~="rti"],
        &[class~="stanford-cset"],
        &[class~="maryland"],
        &[class~="errorCorp"],
        &[class~="bpLogo"],
        &[class~="clevelandStateUniversity"],
        &[class~="UCIrvine"],
        &[class~="quantumStartupFoundary"] {
          max-width: 70%;
        }

        &[class~="jrotc"] {
          max-width: 50%;
        }
      }

      &.remaining {
        display: none;

        &.visible {
          display: grid;
        }
      }
    }

    .collapseBtn {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      font-style: italic;
      padding: 12px 24px;
      display: block;
      margin: 30px auto 0;

      .arrow {
        svg {
          transform: rotate(0deg);
          transition: $transition;

          path {
            fill: $white;
          }
        }

        &.expanded {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}