.coursesBy {
  padding: 50px 0;
  .thinner {
    display: flex;
    align-items: center;
    justify-content: center;
    .subHeading {
      margin-bottom: 0;
    }
    .cbList {
      li {
        img {
          height: 60px;
        }
      }
    }
  }
  .subHeading {
    font-size: 24px;
    line-height: 30px;
    font-family: "seguibl";
    text-align: center;
  }
  .cbList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) {
      justify-content: space-between;
    }
    li {
      flex: 0 0 calc(50% - 30px);
      margin: 15px;
      text-align: center;
      @media (min-width: 992px) {
        margin-bottom: 0;
        flex: 1;
      }
      img {
        height: 90px;
        width: auto;
      }
    }
  }
}
