@import "src/assets/css/helpers/variables.scss";

.programDetails {
  padding: 50px 0;
  background-color: $bg-gray;
  .title {
    font-weight: 600;
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .item {
    &:not(:last-child) {
      margin-bottom: 30px;
      @media (min-width: 768px) {
        margin-bottom: 0px;
      }
    }
    .card {
      border-radius: 8px;
      height: 100%;
      .cardTitle {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
      }
      .cardDesc {
        margin-bottom: 0;
        font-size: 16px;
      }
    }
  }
  .disclaimer {
    font-size: 24px;
    line-height: 30px;
    color: $white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
  }
}
