.keyDetails {
  list-style-type: none;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
  .kdWrap {
    display: flex;
    flex-flow: column wrap;
    box-shadow: 0px 0px 8px 4px rgba(163, 163, 163, 0.2);
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
    color: #fff;
    height: 100%;
    padding: 30px;
    justify-content: center;
    .icon {
      font-size: 40px;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
