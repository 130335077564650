@import "src/assets/css/helpers/variables.scss";

.programCompletion {
  padding: 50px 0;
  background-color: $bg-gray;
  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .keypoints {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .icon {
        display: flex;
        margin-right: 15px;
        svg {
          width: 60px;
          height: 60px;
          text {
            font-size: 12px;
            font-weight: 500;
            text-anchor: middle;
          }
        }
      }
      .content {
        flex: 0 0 calc(100% - 75px);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
