@import "src/assets/css/helpers/variables.scss";

.programOverview {
  padding: 50px 0;
  .contentWrap {
    margin-bottom: 50px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    .title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
      @media(min-width: 1200px) {
        text-align: justify;
      }
      span {
        &:before {
          bottom: 0;
          left: 0;
        }
      }
    }
    .subTitle {
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      @media(min-width: 1200px) {
        text-align: justify;
      }
    }
    .desc {
      font-size: 16px;
      margin-bottom: 0;
      @media(min-width: 1200px) {
        text-align: justify;
      }
    }
  }
}
