@import 'src/assets/css/helpers/variables.scss';

.ypab {
  .banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 26px 25px -20px rgba(0, 0, 0, 0.29);
    padding: 15px;
    min-height: 400px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 991px) {
      margin-top: 0;
      min-height: 200px;
    }

    .yabText {
      font-size: 44px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 2;
      margin-bottom: 0;
      @media (min-width: 992px) {
        font-size: 32px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $brand;
      opacity: 0.6;
    }
  }

  .aboutYpab {
    margin-bottom: 50px;
    .imgHeaderRight {
      display: none;
    }
    @media (min-width: 992px) {
      margin-bottom: 100px;
      .imgHeaderRight {
        display: block;
      }
    }
    .textDetail {
      text-align: center;
      margin: auto;
      @media (min-width: 768px) {
        text-align: justify;
        text-align-last: center;
      }
      .heading {
        color: #346e8a;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        @media (min-width: 768px) {
          font-size: 40px;
        }
      }
      .content {
        font-size: 18px;
        text-align: justify;
        text-align-last: center;
      }
    }
  }

  .youngProfessionalsBlock {
    gap: 30px;
    display: grid;
    margin-bottom: 50px;
    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    .professionals {
      .avatar {
        object-fit: cover;
        object-position: top center;
        border-radius: 12px;
        display: block;
        margin: 0 auto 20px;
        width: 100%;
        @media (min-width: 576px) {
          height: 250px;
        }
        @media (min-width: 768px) {
          height: 157px;
        }
        @media (min-width: 992px) {
          height: 217px;
        }
        @media (min-width: 1200px) {
        height: 260px;

        }
      }

      .meta {
        text-align: center;

        .name {
          font-size: 14px;
          list-style: 21px;
          margin-bottom: 10px;
        }

        .organization {
          max-width: 80px;
          width: 100%;
          margin: auto;
        }
      }
    }
  }

  .faqSection {
    padding: 30px 0;
    background-color: #f9f9f9;
    @media (min-width: 768px) {
      padding: 50px 0;
    }
    div[id~='accordion'] {
      .card {
        border: 0;
        background-color: transparent;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        .cardHeader {
          h5 {
            button {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              text-align: left;
              width: 100%;
              background: linear-gradient(
                to right,
                #38c1cf 0%,
                #5bcbb8 50%,
                #005d92 50.1%,
                #38b6ff 100%
              );
              background-size: 200%;
              border: 0;
              padding: 15px;
              border-radius: 4px;
              background-position: 0 0;
              transition: 0.3s ease all;
              span {
                color: #fff;
              }
              &:focus {
                outline: 0;
                box-shadow: none;
              }
              .chText {
                flex: 0 0 calc(100% - 48px);
                margin-right: 16px;
                font-size: 20px;
              }
              svg {
                flex: 0 0 32px;
                width: 32px;
                height: 32px;
                path {
                  fill: #fff;
                }
              }
            }
            button[class~='collapsed'] {
              background-position: -100% 0;
            }
          }
        }

        .tcCollapse {
          height: 0;
          transition: 0.3s ease all;
          .cardBody {
            margin-top: 10px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0px 0px 6px 2px rgba(56, 193, 207, 0.1);
            p {
              margin-bottom: 0;
              font-size: 18px;
            }
          }
        }
        div[class~='show'] {
          height: auto;
        }
      }
    }
  }

  .postFaq {
    padding: 50px 0;
    h3 {
      color: #346e8a;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
      a {
        font-weight: 800;
        transition: $transition;
        color: $gold;
      }
    }
    p {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 0;
    }
  }
}
