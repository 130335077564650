@import "src/assets/css/helpers/variables.scss";

.courses {
  .coursesBanner {
    background-position: top left;
    background-color: #f2f2f2;
    @media (min-width: 1200px) {
      background-size: contain;
    }
  }
  .testimony {
    padding: 50px 0;
    .containerBig {
      @media (min-width: 1440px) {
        max-width: 1660px;
      }
    }
    &.grayBg {
      background-color: $bg-gray;
    }
  }
  .elevateLearningWrapper {
    background-color: transparent;
    padding: 50px 0 100px;
  }
}
