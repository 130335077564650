@import "src/assets/css/helpers/variables.scss";

.testimony {
  .overlay {
    background-color: $brand-light;
  }
}
.icon {
  margin-bottom: 10px;
  svg {
    width: 40px;
    height: 40px;
    path,
    rect {
      fill: $white;
    }
  }
}
