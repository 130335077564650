.ourLeadershipBlock {
  position: relative;
  padding: 40px 15px;

  .detailBlock {
    position: relative;
    z-index: 2;

    @media (min-width: 992px) and (max-width: 1440px) {
      max-width: 80%;
      margin: auto;
    }

    .title {
      color: #50759d;
      font-size: 40px;
      line-height: 1;
      text-align: center;
      margin: 40px 0;
    }
  }

  .advisors {
    max-width: 250px;
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top center;
    margin: 0 0 15px;
    border-radius: 12px;
    color: #50759d;

    @media (min-width: 992px) and (max-width: 1440px) {
      height: 200px;
    }
  }
}