@import "src/assets/css/helpers/variables.scss";

.forSchools {
  padding: 50px 0;

  @media (min-width: 992px) {
    padding: 100px 0;
  }

  &.courses {
    background-color: $bg-gray;

    .containerBig {
      header {
        @media (min-width: 992px) {
          margin: 0 auto 30px;
        }
      }
    }
  }

  .containerBig {
    max-width: 1660px;

    header {
      max-width: 992px;
      display: table;
      margin: 0 auto 30px;
      text-align: center;

      @media (min-width: 992px) {
        margin: 0 auto 100px;
      }

      &.isSemesterCourse {
        margin: 0 auto 10px;

        @media (min-width: 992px) {
          margin: 0 auto 10px;
        }
      }

      .heading {
        font-size: 36px;
        line-height: 42px;
        font-family: "seguibl";
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 46px;
          line-height: 52px;
        }

        @media (min-width: 992px) {
          font-size: 56px;
          line-height: 62px;
        }

        .textThin {
          font-size: 28px;
          line-height: 34px;
          font-family: "Segoe UI Bold";

          @media (min-width: 768px) {
            font-size: 34px;
            line-height: 40px;
          }

          @media (min-width: 992px) {
            font-size: 46px;
            line-height: 52px;
          }
        }
      }

      .subTitle {
        font-size: 22px;
        line-height: 28px;
        font-family: "Segoe UI Bold";
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 28px;
          line-height: 34px;
        }

        @media (min-width: 992px) {
          font-size: 34px;
          line-height: 40px;
        }
      }
    }
  }

  .schoolBox {
    border: 3px dashed #346e8a;
    box-shadow: 0 0 25px -2px #dedede;
    padding: 40px;

    p {
      font-size: 18px;
      line-height: 24px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .rightArea {
    margin: auto;
    max-width: 200px;
    text-align: center;

    &.isSemesterCourse {
      max-width: 100%;
    }

    @media (min-width: 768px) {
      max-width: 300px;
    }

    @media (min-width: 992px) {
      max-width: 380px;
    }

    p {
      font-size: 18px;
      line-height: 24px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .logos {
      li {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &.isSemesterCourse {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        li {
          img {
            max-width: 300px;
          }

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      flex: 1 100%;
      margin-top: 30px;

      .button {
        max-width: 180px;
        width: 100%;
      }
    }
  }
}