@import "src/assets/css/helpers/variables.scss";
@import "src/assets/css/helpers/animation.scss";

.whatStudentsLearn {
  background-image: url("/assets/img/research/logistics-bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($blue, 0.1);
  }

  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 30px;
    color: $white;
  }

  .sectionTitle {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    color: $white;
    text-align: center;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: $white;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &.hsResearchDesc {
      text-align: justify;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      padding-left: 24px;
      &:before {
        content: "";
        width: 12px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        top: 9px;
        left: 0;
        border-radius: 100px;
      }
    }
  }

  div[id~="accordion"] {
    max-width: 750px;
    margin: auto;
    .card {
      border: 0;
      background-color: transparent;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .cardHeader {
        h5 {
          button {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            background: linear-gradient(
              to right,
              #38c1cf 0%,
              #5bcbb8 50%,
              #005d92 50.1%,
              #38b6ff 100%
            );
            background-size: 200%;
            border: 0;
            padding: 10px;
            border-radius: 4px;
            background-position: 0 0;
            transition: 0.3s ease all;
            span {
              color: #fff;
            }
            &:focus {
              outline: 0;
              box-shadow: none;
            }
            .chText {
              flex: 0 0 calc(100% - 40px);
              margin-right: 16px;
            }
            svg {
              flex: 0 0 24px;
              path {
                fill: #fff;
              }
            }
          }
          button[class~="collapsed"] {
            background-position: -100% 0;
          }
        }
      }
  
      .tcCollapse {
        height: 0;
        transition: 0.3s ease all;
        .cardBody {
          margin-top: 10px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 6px 2px rgba(56, 193, 207, 0.1);
          p {
            margin-bottom: 0;
          }
        }
      }
      div[class~="show"] {
        height: auto;
      }
    }
  }
}