.stemWorkforce {
  position: relative;
  padding: 80px 0;
  .overlayImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: grayscale(1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(52, 110, 138, 0.651);
    z-index: 2;
  }
  .keyInitiativesContent {
    position: relative;
    z-index: 3;
    .visualText {
      h4 {
        font-size: 48px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 30px;
        color: #fff;
        @media screen and (max-width: 768px) {
          font-size: 40px;
        }
        @media screen and (max-width: 767px) {
          font-size: 30px;
        }
      }
      h3 {
        @media screen and (max-width: 767px) {
          font-size: 30px !important;
          text-align: center;
        }
      }
      p {
        font-size: 20px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
  }
}
