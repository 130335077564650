@import "src/assets/css/helpers/variables.scss";
@import "src/assets/css/helpers/animation.scss";

.whatStudentsLearn {
  background-image: url("/assets/img/research/logistics-bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($blue, 0.1);
  }

  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 30px;
    color: $white;
  }

  .keypoints {
    list-style-type: none;
    margin-bottom: 30px;

    &.bulletInlined {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media(min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    li {
      font-size: 16px;
      display: flex;
      align-items: center;
      color: $white;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:before {
        content: "";
        background-image: url("/assets/img/common/icon-atom.png");
        background-size: 100%;
        animation: rotate 4s linear infinite;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        background-repeat: no-repeat;
      }
    }
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: $white;
  }
}