@import 'src/assets/css/helpers/variables.scss';

.tcsStories {
  padding: 50px 0;
  background: linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea);
  border-top: 50px solid $gold;

  header {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 50px;
      font-weight: bold;
      color: $brand;

      @media (max-width: 991px) {
        font-size: 36px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }

    h3 {
      margin-bottom: 0;

      @media (max-width: 767px) {
        font-size: 22px;
      }

      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
  }

  .tcsContainer {
    width: 75%;
    margin: auto;
  }

  .tcsStoryWrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .tcsStoryCard {
    flex: 0 0 25%;
    padding: 0 15px;
    margin-bottom: 30px;

    &.bigCard {
      flex: 0 0 50%;
    }
  }

  .postCard {
    position: relative;
    height: 100%;
    box-shadow:
      0 0 10px rgba(0, 0, 0, 0.15),
      0 3px 3px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    transition: 0.3s ease all;
    background-color: #fff;

    .imgArea {
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s ease all;
      }
    }

    img,
    &.bigCard {
      .imgArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .cardContent {
        transform: scale(1);
        transition: 0.3s ease all;
      }
    }

    &:hover {
      box-shadow:
        0 0 10px rgba(0, 0, 0, 0.2),
        0 7px 10px rgba(0, 0, 0, 0.2);

      img,
      &.bigCard .cardContent {
        transform: scale(1.01);
      }
    }

    &:not(.bigCard) {
      .imgArea {
        padding-top: 66.6666666667%;
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .cardContent {
        padding: 30px;

        @media (min-width: 1200px) {
          padding: 30px 15px;
        }

        @media (min-width: 1400px) {
          padding: 30px;
        }

        .category {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 17px;
          color: $brand;
          margin-bottom: 0;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }

    &.bigCard {
      .cardContent {
        position: absolute;
        bottom: 0;
        padding: 28px;
        background: linear-gradient(180deg,
            transparent,
            rgba(0, 0, 0, 0.85) 80%);
        width: 100%;

        .category {
          color: #fff;
          text-shadow: rgba(0, 0, 0, 0.6) 0 0 2px;
          font-size: 18px;
          margin-bottom: 0;
        }

        .title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }
      }
    }
  }

  .button {
    font-size: 18px;
    font-weight: 700;
    padding: 20px 45px;
  }

  @media (max-width: 1099px) {
    .tcsStoryCard {
      &:not(.bigCard) {
        .cardContent {
          padding: 15px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .tcsContainer {
      width: 100%;
      padding: 0 15px;
    }

    .tcsStoryCard {
      &:first-child {
        flex: 0 0 66.6666%;
      }

      &:not(:first-child) {
        flex: 0 0 33.3333%;
      }

      &.story-6 {
        display: none;
      }

      &:not(.bigCard) {
        .cardContent {
          .title {
            font-size: calc(18px + (3 * ((100vw - 768px) / 768)));
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .tcsStoryCard {
      &:first-child {
        flex: 0 0 100%;
        height: 400px;
      }

      &:not(:first-child) {
        flex: 0 0 50%;
      }

      &:not(.bigCard) {
        .cardContent {
          .title {
            font-size: calc(18px + (3 * ((100vw - 768px) / 768)));
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .tcsStoryCard {
      &:not(:first-child) {
        flex: 0 0 100%;

        .imgArea {
          display: none;
        }

        .cardContent {
          padding: 10px;

          .category {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            color: $brand;
            margin-bottom: 0;
            text-shadow: none;
          }

          .title {
            font-size: 16px;
            font-weight: 700;
            color: #000;
          }
        }
      }

      &:first-child {
        height: 250px;

        .cardContent {
          padding: 10px;

          .category {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            color: $brand;
            margin-bottom: 0;
            text-shadow: none;
          }

          .title {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}