@import 'src/assets/css/helpers/variables.scss';

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  position: relative;

  @media (min-width: 1200px) {
    background-size: contain;
  }

  .particlesArea>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 25px 0;
    max-width: 768px;
    width: 100%;
    margin: auto;
    text-align: center;

    @media (min-width: 992px) {
      padding: 100px 0;
    }

    &.coursesContent {
      max-width: 100%;

      @media (min-width: 992px) {
        padding: 50px 0 20px;
      }

      h1 {
        font-weight: 600;
        font-family: 'Poppins';
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 28px;
        @media (min-width: 992px) {
          font-size: 36px;
          line-height: 52px;
        }
      }

      h3 {
        font-size: 26px;
        @media (min-width: 992px) {
          font-size: 46px;
        }
        line-height: 1.2;
        font-weight: 900;
        font-family: 'Segoe UI Bold';
        border-radius: 8px;
        padding: 20px;
        border: 0;
      }

      h4 {
        
        font-size: 22px;
        line-height: 28px;
        @media (min-width: 992px) {
          font-size: 32px;
        line-height: 38px;
        }
      }

      .coursesBy {
        margin-bottom: 50px;

        p {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 15px;
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;

          li {
            width: 20%;

            img {
              height: 35px;
              width: auto;
            }
          }
        }
      }

      h5 {
        font-weight: 400;
      }
    }

    h1 {
      font-size: 50px;
      line-height: 56px;
      margin-bottom: 30px;
      font-weight: 900;
      font-family: 'Segoe UI Bold';
    }

    h3 {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;
      padding: 10px;
      border: 4px solid $brand-light;
      border-radius: 50px;
      position: relative;

      @media (min-width: 992px) {
        font-size: 24px;
        line-height: 30px;
        border-radius: 100px;
        margin-bottom: 30px;
      }
    }

    h4,
    .programsTypesStyle {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;

      @media (min-width: 992px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    h5 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;

      @media (min-width: 992px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .bannerButtons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;

    li {
      max-width: 270px;
      width: 100%;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid $brand;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        padding: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        min-height: 81px;

        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          position: relative;
          z-index: 2;
          color: $brand;
          transition: $transition;

          span {
            display: block;
            font-size: 14px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: -800px;
          left: -850px;
          width: 1000px;
          height: 1000px;
          border-radius: 100%;
          transition: $transition;
          background: linear-gradient(94deg,
              rgba(52, 110, 138, 1) 0%,
              rgba(122, 205, 246, 1) 100%);
        }

        &:hover {
          color: #fff;

          span {
            color: #fff;
          }

          &:before {
            top: -650px;
            left: -400px;
            border-radius: 0;
          }
        }
      }
    }
  }
}