@import 'src/assets/css/helpers/variables.scss';

.videoSection {
  padding: 50px 0;
  background-color: $gray-2;
  @media (min-width: 992px) {
    padding: 100px 0;
  }
  video{
    width: 100%;
    height: auto;
  }
  p {
    font-size: 18px;
    line-height: 27px;
  }
}