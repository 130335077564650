@import 'src/assets/css/helpers/variables.scss';

.professionalBlock {
  position: relative;
  margin: auto;
  background: #ededed;
  padding: 30px 0;

  .background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 26px 25px -20px rgba(0, 0, 0, 0.29);
    padding: 15px;
    min-height: 300px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 991px) {
      margin-top: 0;
      min-height: 200px;
    }

    .yabText {
      font-size: 26px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 2;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $brand;
      opacity: 0.6;
    }
  }

  .youngProfessionalsBlock {
    gap: 30px;

    .professionals {
      .avatar {
        width: 162px;
        height: 162px;
        object-fit: cover;
        object-position: top center;
        border-radius: 12px;
        display: block;
        margin: 0 auto 20px;
      }

      .meta {
        text-align: center;

        .name {
          font-size: 14px;
          list-style: 21px;
          margin-bottom: 10px;
        }

        .organization {
          max-width: 80px;
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}