@import 'src/assets/css/helpers/variables.scss';

.StudentMapCounter {
  padding: 80px 0;
  .imageBox {
    position: relative;
    display: block;
    text-align: center;
    img {
      margin-bottom: 15px;
    }
    h4 {
      font-size: 70px;
      font-weight: bold;
      color: $gold;
      text-align: center;
      div {
        @media screen and (max-width: 768px) {
          height: 60px !important;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
    }
    h6 {
      font-size: 40px;
      font-weight: bold;
      color: #346e8a;
      line-height: 1;
      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
    }
  }
  .dotMap {
    position: relative;
    img {
      pointer-events: none;
      max-width: 50%;
      width: auto;
      display: block;
      margin: 0 auto;
      min-height: 87px;
    }

    .imageBoxWrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      padding-top: 100px;
      opacity: 1;
      transition: all 0.5s ease;
      @media screen and (max-width: 767px) {
        position: relative;
        padding-top: 50px;
        opacity: 1;
      }
    }
  }
}
.visualText {
  h3 {
    font-size: 50px;
    font-weight: bold;
    color: $brand;
  }
}
