@import 'src/assets/css/helpers/variables.scss';

.getInvolvedBlock {
  background: url('../../assets/img/involved/get-involved-splash-bg.jpg') center center;
  background-size: cover;
  width: 100vw;
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;

  h1 {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Poppins';
    font-weight: bold;
    color: #356e8b;
    font-size: 40px;

    @media (min-width: 992px) {
      font-size: 60px;
    }

    @media (min-width: 1200px) {
      font-size: 100px;
    }
  }
}

.involvedRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  height: 200px;
  color: white;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  padding: 40px;

  &:first-child {
    background: url('../../assets/img/involved/partner-with-us.png') center center;
  }

  &:nth-child(2) {
    background: url('../../assets/img/involved/bring-a-course.png') center center;

    .line {
      background: white;
      width: 180px;
      height: 2px;
    }
  }

  &:nth-child(3) {
    background: url('../../assets/img/involved/teach-or-mentor.png') center center;
  }

  &:nth-child(4) {
    background: url('../../assets/img/involved/volunteer.png') center center;
  }
}

.details {
  max-width: 800px;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: none;
}

.invovledForm {
  height: 1579px;
  width: 100%;
  border: 0;
  margin-top: 50px;
}