.bringAi {
  padding: 30px 0;
  position: relative;
  background: url("../../../assets/img//train/section4-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 9;
  @media (min-width: 768px) {
    padding: 30px 0 230px;
  }
  .tagline {
    h4 {
      font-size: 20px;
      line-height: 30px;
      font-family: "Segoe UI Bold";
      color: #fff;
      @media (min-width: 992px) {
        font-size: 25px;
        line-height: 35px;
      }
      @media (min-width: 1200px) {
        font-size: 36px;
        line-height: 42px;
      }
    }
  }
  .headline {
    h2 {
      font-size: 30px;
      line-height: 40px;
      font-family: "seguibl";
      text-transform: uppercase;
      color: #fff;
      @media (min-width: 992px) {
        font-size: 50px;
        line-height: 60px;
      }
      @media (min-width: 1200px) {
        font-size: 70px;
        line-height: 80px;
      }
    }
  }
  .desc {
    p {
      font-size: 14px;
      line-height: 20px;
      color: #f3f3f3;
      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .more {
    margin: 50px auto 0;
    a {
      background: #fff;
      color: #000;
      padding: 13px 50px;
      border-radius: 10px;
      font-size: 20px;
      line-height: 27px;
      font-family: "Segoe UI Bold";
      position: relative;
      overflow: hidden;
      display: inline-block;
      z-index: 9999999999;
      i {
        padding-left: 10px;
        font-size: 20px;
        position: relative;
        z-index: 9;
      }
      &:after {
        display: block;
        position: absolute;
        top: -10%;
        right: -120px;
        width: 200px;
        height: 200px;
        position: absolute;
        content: "";
        background: rgb(0, 93, 146);
        background: radial-gradient(
          circle,
          rgba(0, 93, 146, 1),
          rgb(56, 182, 255) 78%
        );
        transform: skewX(-30deg);
        transition: 0.5s;
      }
      &:hover:after {
        right: -110px;
      }
    }
  }
}
