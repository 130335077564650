@import "src/assets/css/helpers/variables.scss";

.NotFound {
  padding: 100px 0;
  text-align: center;
  background-color: $bg-gray;
  h1 {
    font-size: 50px;
    font-weight: bold;
  }
  p {
    font-size: 22px;
  }
  .button {
    font-size: 18px;
  }
}
