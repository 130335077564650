@import "src/assets/css/helpers/animation.scss";

.AiIntro {
  background: linear-gradient(to right, #005d92, #38b6ff, #005d92);
  padding: 50px 0;
  @media (min-width: 992px) {
    padding: 100px 0;
  }
  .heading {
    font-size: 32px;
    line-height: 38px;
    font-family: "seguibl";
    text-transform: uppercase;
    color: #fff;
    span {
      color: #005d92;
    }
    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 46px;
    }
    @media (min-width: 992px) {
      font-size: 55px;
      line-height: 61px;
    }
  }
  .desc {
    font-size: 18px;
    line-height: 24px;
    color: #f3f3f3;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 26px;
    }
    &.tagline {
      font-size: 24px;
      line-height: 30px;
      @media (min-width: 992px) {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  .characteristicsList {
    list-style-type: none;
    li {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 992px) {
        font-size: 22px;
        justify-content: flex-start;
      }
      i {
        margin-right: 10px;
        animation: grow 2s infinite alternate;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
