@import "assets/css/global.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

body {
  font-family: $themeFont;
  font-weight: 400;
  font-size: 14px;
  color: $paragraph;
  margin: 0;
  padding: 0;
  color: #000;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
