@import 'src/assets/css/helpers/variables.scss';

.emergingTechPrograms {
  background-color: $bg-gray;
  padding: 50px 0;

  @media (min-width: 992px) {
    padding: 100px 0;
  }

  .containerBig {
    @media (min-width: 1440px) {
      max-width: 1660px;
    }
  }

  header {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 15px;
      color: $brand;

      @media (min-width: 768px) {
        font-size: 50px;
      }
    }

    p {
      font-size: 18px;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .programsList {
    margin-bottom: 30px;
    gap: 30px 0;
    justify-content: center;
    .btnScroll {
      border: 3px solid $brand;
      border-radius: 8px;
      background-color: transparent;
      width: 100%;
      padding: 20px;
      position: relative;
      overflow: hidden;

      span {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        color: $brand;
        transition: $transition;
      }

      &:before {
        content: '';
        position: absolute;
        top: -800px;
        left: -850px;
        width: 1000px;
        height: 1000px;
        border-radius: 100%;
        transition: $transition;
        background: linear-gradient(94deg,
            rgba(52, 110, 138, 1) 0%,
            rgba(122, 205, 246, 1) 100%);
      }

      &:hover {
        color: #fff;

        &:hover {
          span {
            color: #fff;
          }

          &:before {
            top: -650px;
            left: -400px;
            border-radius: 0;
          }
        }
      }
    }
  }

  .sectionWrap {
    &.columnReverse {
      display: flex;
      flex-direction: column-reverse;
      .section {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .section {
      margin-top: 30px;

      .cardWrap {
        padding: 30px;
        border-radius: 8px;

        .sectionTitle {
          text-align: center;
          font-size: 30px;
          line-height: 36px;
          text-transform: uppercase;
          margin-bottom: 30px;
          font-weight: 700;
          color: #fff;

          @media (min-width: 768px) {
            font-size: 40px;
            line-height: 46px;
          }

          @media (min-width: 992px) {
            font-size: 50px;
            line-height: 56px;
          }
        }

        .sectionDescription {
          text-align: center;
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 30px;
          color: #fff;
        }
      }
    }
  }
}