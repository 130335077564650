$count: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100
);

$paragraph: #828282;
$white: #ffffff;
$black: #000000;
$gold: #ffbf00;
$goldTwo: #F3F9A7;
$brand: #356e8b;
$brand-light: #38b6ff;
$blue: #152b60;
$bg-gray: #f9f9f9;
$gray-2: #ebebeb;
$light-red: #f1807e;
$teal: #5bcbb8;

$themeFont: 'Poppins';
$transition: 0.3s ease all;