@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("../../fonts/poppins/Poppins-Thin.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("../../fonts/poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "SEGOEUI";
  src: url("../../fonts/SEGOEUI.woff2") format("woff2"),
    url("../../fonts/SEGOEUI.woff") format("woff"),
    url("../../fonts/SEGOEUI.ttf") format("truetype");
}

@font-face {
  font-family: "seguibl";
  src: url("../../fonts/seguibl.woff2") format("woff2"),
    url("../../fonts/seguibl.woff") format("woff"),
    url("../../fonts/seguibl.ttf") format("truetype");
}

@font-face {
  font-family: "segoeuisl";
  src: url("../../fonts/segoeuisl.woff2") format("woff2"),
    url("../../fonts/segoeuisl.woff") format("woff"),
    url("../../fonts/segoeuisl.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../fonts/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"),
    url("../../fonts/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"),
    url("../../fonts/Segoe UI Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold Italic"),
    url("../../fonts/Segoe UI Bold Italic.woff") format("woff");
}
