@import "src/assets/css/helpers/variables.scss";

.featuredLogo {
  list-style: none;
  padding: 40px 0px;

  .title {
    font-size: 50px;
    font-weight: bold;

    @media (max-width: 991px) {
      font-size: 36px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 20px;
    }
  }

  .slider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    div[class~='slick-arrow'] {
      width: 72px;
      position: relative;
    }

    div[class~='slick-list'] {
      width: calc(100% - 144px);
      overflow: hidden;

      div[class~='slick-track'] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        div[class~='slick-slide'] {
          padding: 0 20px;
        }
      }
    }
  }
}