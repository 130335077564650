@import "src/assets/css/helpers/animation.scss";

.trainBannerBelow {
  background-size: contain;
  background-position: top left;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: url("../../../assets//img//train/s1-bg.png") no-repeat;
    max-width: 400px;
    width: 100%;
    background-size: 100% 100%;
    height: 275px;

    @media (min-width: 992px) {
      height: 400px;
    }
  }

  .tagline {
    h6 {
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      text-align: center;
      margin: 0 auto 30px;
    }
  }

  .desc {
    p {
      font-size: 18px;
      line-height: 24px;
      max-width: 100%;
      margin: 25px auto;
      position: relative;
      z-index: 9999;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
        max-width: 80%;
      }
    }
  }

  .more {
    text-align: center;
    animation: crescendo 1.5s alternate infinite ease-in;

    a {
      background: url("../../../assets/img/train/more-bg.png") no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 44px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: auto;

      .angleDown {
        background: rgb(0, 93, 146);
        background: radial-gradient(circle,
            rgba(0, 93, 146, 1) 0%,
            rgba(56, 182, 255, 1) 78%);
        color: #fff;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 15px;
        font-weight: 100;

        &:before {
          content: '';
          width: 7px;
          height: 7px;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(-45deg);
          top: -2px;
          position: relative;
        }
      }
    }
  }
}