@import "src/assets/css/helpers/variables.scss";

.alertBanner {
  text-align: center;
  background-color: $gold;
  padding: 10px 0;
  color: #000;
  position: relative;
  z-index: 9;

  p {
    margin-bottom: 0;
    font-size: 16px;
  }

  a {
    font-weight: 600;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      width: 50%;
      height: 1px;
      background-color: #000;
      transition: 0.3s ease all;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

header {
  position: relative;
  z-index: 999;

  .topNav {
    z-index: 9;
    width: 100vw !important;
    background-color: white;

    &.transparentBg {
      background-color: transparent;
    }

    .logo {
      width: 100%;
      max-width: 70px;

      @media (min-width: 992px) {
        max-width: 100px;
        position: absolute;
        z-index: 999;
        top: 5px;
      }
    }

    a[class~="nav-link"] {
      font-size: 18px;
    }

    a[class~="register-btn"] {
      svg {
        path {
          fill: #fff;
        }
      }

      &+[class~="dropdown-menu"] {
        right: 0;
        left: auto;
      }
    }

    .dropdownItem {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .navbarToggler {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      width: 34px;
      height: 28px;
      border: 0;
      background-color: transparent;

      @media (min-width: 992px) {
        display: none;
      }

      .icon {
        flex: 0 0 100%;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 2px;
        border-radius: 8px;
      }
    }

    @media (max-width: 991.99px) {
      div[class~="navbar-collapse"] {
        position: absolute;
        top: 90px;
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        border-radius: 8px;

        ul[class~="navbar-nav"] {
          padding-top: 8px;
          padding-bottom: 8px;

          .nav-item {
            padding: 4px 12px;
          }

          a {
            margin-left: 0 !important;
            text-align: center;
          }
        }
      }
    }

    @media (min-width: 992px) {
      div[class~="navbar-collapse"] {
        padding: 10px 0;

        li[class~="nav-item"] {
          div[class~="dropdown-menu"] {
            display: none;
          }
        }

        li[class~="nav-item"]:hover {
          div[class~="dropdown-menu"] {
            display: block;
          }
        }

        li[class~="nav-item"] {
          div[class~="dropdown-menu"] {
            margin-top: 0;
          }
        }
      }
    }
  }
}