@import 'src/assets/css/helpers/variables.scss';

.logistics {
  padding: 50px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  div[class~='container'] {
    position: relative;
    z-index: 1;
    .title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
      color: $white;
      margin-bottom: 50px;
      text-align: center;
    }
    .logisticsTabs {
      justify-content: center;
      gap: 30px;

      .tabTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid $brand;
        border-radius: 8px;
        background-color: #fff;
        padding: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        min-height: 81px;
        max-width: 235px;
        width: 100%;
        transition: $transition;
        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          position: relative;
          z-index: 2;
          color: $brand;
          transition: $transition;

          span {
            display: block;
            font-size: 14px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: -800px;
          left: -850px;
          width: 1000px;
          height: 1000px;
          border-radius: 100%;
          transition: $transition;
          background: linear-gradient(
            94deg,
            rgba(52, 110, 138, 1) 0%,
            rgba(122, 205, 246, 1) 100%
          );
        }

        &:hover,
        &:global(.active) {
          color: #fff;
          span {
            color: #fff;
          }

          &:before {
            top: -650px;
            left: -400px;
            border-radius: 0;
          }
        }
        &:global(.active) {
          border-color: $gold;
        }
      }
    }
    .logisticsList {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
      }
      li {
        &:not(:last-child) {
          border-bottom: 3px solid #c7ecff73;
        }
        @media (min-width: 576px) {
          grid-template-columns: repeat(2, 1fr);
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(5) {
            border-right: 3px solid #c7ecff73;
          }
          &:nth-child(5) {
            border-bottom: 0;
          }
        }
        @media (min-width: 992px) {
          grid-template-columns: repeat(3, 1fr);
          &:nth-child(2),
          &:nth-child(4) {
            border-right: 3px solid #c7ecff73;
          }
          &:nth-child(3) {
            border-right: 0;
          }
          &:nth-child(4) {
            border-bottom: 0;
          }
        }
        padding: 30px 15px;
        .subTitle {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          color: $gold;
          display: block;
          margin-bottom: 10px;
          flex: 0 0 100%;
        }
        .desc {
          font-size: 16px;
          font-weight: 500;
          color: $white;
          flex: 0 0 100%;
        }
      }
    }

    .extraInfoWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .extraInfo {
        border: 2px solid $gold;
        border-radius: 4px;
        display: inline-block;
        padding: 10px 20px;
        text-align: center;
        h5 {
          color: $white;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 0;
          color: $white;
        }
      }
    }
  }
}
