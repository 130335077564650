@import 'src/assets/css/helpers/variables.scss';

.donatePage {
  margin: auto;
  header {
    position: relative;
    z-index: 1;
    .title {
      font-size: 40px;
      line-height: 46px;
      font-weight: 800;
      margin-bottom: 20px;
      @media (min-width: 768px) {
        font-size: 56px;
        line-height: 62px;
      }
    }
    .content {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .banner {
    background: url('../../assets/img/donate/donate-banner.jpg') center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 400px;
    position: relative;
    padding: 50px 0;
    @media (min-width: 768px) {
      padding: 100px 0;
      min-height: 633px;
    }
    header {
      text-align: center;
      margin-bottom: 20px;
      .title {
        color: $brand;
        font-size: 26px;
        line-height: 32px;
        @media (min-width: 768px) {
          font-size: 40px;
          line-height: 46px;
        }
      }
      .subTitle {
        color: $brand;
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .giveLively {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-radius: 6px;
      font-family: $themeFont !important;
      [class~='gl-widget--selected-btn'] {
        border-color: $brand !important;
        color: $brand !important;
        &:hover,
        &:focus {
          background-color: $brand !important;
          color: $white !important;
        }
      }
      [class~='gl-widget__btn--primary'] {
        border-color: $brand !important;
        color: $white !important;
        background-color: $brand !important;
        &:hover,
        &:focus {
          color: $white !important;
          background-color: transparentize($brand, 0.2) !important;
        }
      }
    }
  }

  .countWrap {
    text-align: center;
    .count {
      font-size: 50px;
      font-weight: bold;
      color: $gold;
      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
    }
    .content {
      font-size: 26px;
      font-weight: bold;
      color: $brand;
      text-transform: uppercase;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  .impact {
    .title {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      text-align: center;
      color: $brand;
      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 46px;
      }
    }
    .content {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .infoWrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      border-radius: 6px;
      .icon {
        display: flex;
        margin-bottom: 30px;
        svg {
          width: 120px;
          height: 120px;
          text {
            font-size: 12px;
            font-weight: 500;
            text-anchor: middle;
          }
        }
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .creditSec {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .subTitle {
      color: #232323;
      font-size: 16px;
      margin-bottom: 15px;
      max-width: 100%;
      a {
        color: #007886;
      }
    }
    &.poweredBtn {
      padding: 0 0;
      margin-bottom: 15px;
    }
    .giveLively {
      max-width: 200px;
      width: 100%;
    }
  }

  .donateBtn {
    max-width: 200px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    display: block;
    margin: auto;
    padding: 12px 20px;
  }

  .quote {
    margin-top: 20px;
  }
}
