@import "src/assets/css/helpers/variables.scss";

.leadInstructors {
  position: relative;
  padding: 50px 0;
  background-color: $bg-gray;

  &.multiple {
    .heading {
      text-align: center;

      &:after {
        left: 0;
        right: 0;
      }
    }

    .innerRow {
      gap: 30px 0;
    }

    .instructorWrap {
      background-color: $white;
      box-shadow: 0px 2px 6px 4px rgba(163, 163, 163, 0.2);
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      img {}

      .content {
        padding: 30px;

        .name {
          font-weight: 800;
          font-size: 22px;

          span {
            font-size: 16px;
          }
        }

        .about {
          font-size: 16px;
          margin-bottom: 0;

          @media (min-width: 992px) {
            min-height: 312px;
          }
        }
      }
    }
  }

  &:before {
    content: "";
    background: url("../../../assets/img/train/s1-bg-new.png") no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
    height: 440px;
    background-size: 100% 100%;
  }

  .imgWrap {
    img {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
      position: absolute;
      top: 20px;
      left: 20px;
      width: 100%;
      height: 100%;
    }
  }

  .heading {
    font-size: 36px;
    line-height: 42px;
    font-family: "seguibl";
    text-transform: uppercase;
    margin-bottom: 50px;
    position: relative;

    @media (min-width: 768px) {
      font-size: 46px;
      line-height: 52px;
    }

    @media (min-width: 992px) {
      font-size: 56px;
      line-height: 62px;
    }

    .textThin {
      font-size: 28px;
      line-height: 34px;
      font-family: "Segoe UI Bold";

      @media (min-width: 768px) {
        font-size: 34px;
        line-height: 40px;
      }

      @media (min-width: 992px) {
        font-size: 46px;
        line-height: 52px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 200px;
      width: 100%;
      height: 6px;
      background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);

      @media (min-width: 992px) {
        right: auto;
      }
    }
  }

  .desc {
    text-align: center;
    font-size: 20px;
  }

  .about {
    .instructorName {
      font-family: "seguibl";
      text-transform: uppercase;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 20px;
    }

    .instructorAbout {
      font-size: 18px;
      margin-bottom: 0;
    }

    .readMoreBtn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-start;
      }

      .icon {
        margin-left: 8px;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
}