@import 'src/assets/css/helpers/variables.scss';

.partnerSponsor {
  background-image: linear-gradient(to bottom,
      #fff 50%,
      #346e8a7d 50.1%,
      #346e8a7d 100%);

  .psWrap {
    border: 1px solid #ace1fb;
    padding: 40px 30px 30px;
    background: #fff;
    display: block;
    z-index: 1;
    position: relative;

    .title {
      font-size: 50px;
      font-weight: bold;
      color: $brand;

      @media (max-width: 991px) {
        font-size: 36px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }

    .desc {
      display: grid;
      font-size: 18px;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      flex-flow: wrap;
      justify-content: flex-start;
    }

    li {
      list-style: none;

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }

      img {
        max-width: 100%;
      }

      @media screen and (max-width: 767px) {
        height: auto;
      }
    }
  }

  .btn {
    min-width: 260px;
  }
}

.newSec {
  background-image: linear-gradient(to bottom,
      #346e8a7d 60%,
      #fff 60.1%,
      #fff 100%);
  display: block;

  .title {
    font-size: 50px;
    font-weight: bold;

    @media (max-width: 991px) {
      font-size: 36px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
}

.latestTcsNews {
  .ltcsnWrap {
    border: 1px solid #ace1fb;
    padding: 40px 30px 30px;
    background: #fff;
    display: block;
    z-index: 1;
    position: relative;

    .ltcsnCard {
      background: #fff;
      box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
      border-radius: 8px;
      overflow: hidden;
      border: 2px solid #dedddd;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media (max-width: 768px) {
        padding: 0;
      }

      img {
        max-width: 230px;
        width: 100%;
        height: auto;

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }

      .ltcsnCardContent {
        margin-left: 15px;
        width: calc(100% - 245px);

        @media (max-width: 768px) {
          width: 100%;
          margin-left: 0;
          margin-top: 15px;
          padding: 15px;
          text-align: center;
        }

        .ltcsnTitle {
          margin-bottom: 0;
          a {
            text-transform: uppercase;
            font-weight: 800;
            font-size: 20px;
            display: block;
            transition: $transition;

            &:hover {
              color: $brand;
            }
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.homeBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  gap: 15px 0;

  @media (min-width: 768px) {
    flex-flow: row wrap;
    gap: 0 15px;
  }

  .btn {
    max-width: 300px;
    width: 100%;
  }
}