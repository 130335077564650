@import 'src/assets/css/helpers/variables.scss';
@import "src/assets/css/helpers/animation.scss";

.socialEquity {
  position: relative;
  padding: 40px 15px;
  background-color: #e2e2e2;
  animation: bgGradient 15s ease infinite;
  background: linear-gradient(-45deg, #005d92, #38b6ff, #38c1cf, #5bcbb8);
  background-size: 400% 400%;

  .title {
    font-size: 40px;
    font-weight: 600;

    .titleFirst {
      font-size: 28px;
      display: block;
    }
  }

  .desc {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
  }
}