.BringAiCards {
  position: relative;
  z-index: 9;
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: -170px;
  }
  .containerBig {
    max-width: 1660px;
  }
  .bacFirst {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  .main {
    box-shadow: 0px 3px 49px #0000001a;
    background: #fff;
    padding: 50px;
    border-radius: 21px;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    .headline {
      margin-bottom: 15px;
      h4 {
        color: #000000;
        font-family: "Segoe UI Bold";
        font-size: 20px;
        line-height: 36px;
        @media (min-width: 992px) {
          font-size: 26px;
          line-height: 32px;
        }
        @media (min-width: 1200px) {
          font-size: 36px;
          line-height: 42px;
        }
      }
    }
    .desc {
      margin-bottom: 30px;
      p {
        color: #818181;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        @media (min-width: 992px) {
          font-size: 18px;
          line-height: 24px;
        }
        a {
          font-weight: 700;
          color: #989898;
          text-decoration: underline;
        }
      }
    }
    .button {
      max-width: 280px;
      width: 100%;
      margin: 0 auto 0;
      @media (min-width: 768px) {
        margin: auto 0 0;
      }
    }
  }
}
