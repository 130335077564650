@import 'src/assets/css/helpers/variables.scss';

.professors {
  background-color: $bg-gray;
  padding: 50px 0;

  .title {
    font-size: 44px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .tabPills {
      margin-bottom: 30px;
      gap: 10px;

      @media (min-width: 992px) {
        flex: 0 0 300px;
        margin-right: 30px;
        margin-bottom: 0;
      }

      .pill {
        flex: 0 0 calc(50% - 5px);
        &:first-child {
          flex: 0 0 100%;
        }
        @media (min-width: 992px) {
          flex: 0 0 100%;
        }

        border: 0;
        padding: 12px 8px;
        border-radius: 6px;
        background-color: transparent;
        background-image: linear-gradient(to right, #38c1cf, #5bcbb8);
        background-size: 200% 100%;
        background-position: 0 0;
        transition: $transition;

        &:hover {
          background-position: 100% 0;
        }

        &[class~='active'] {
          background-position: 100% 0;
        }

        .tabTitle {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          color: $white;
        }

        .avatar {
          width: 45px;
          height: 45px;
          border-radius: 100px;
          margin-right: 15px;
          box-shadow: 2px 1px 5px 1px rgba(163, 163, 163, 0.3);
        }

        .name {
          flex: 0 0 calc(100% - 60px);
          text-align: left;
          font-weight: 500;
        }
      }
    }

    .tabContent {
      @media (min-width: 992px) {
        flex: 0 0 calc(100% - 330px);
      }

      .tabPane {
        padding: 30px;
        background-color: #fff;
        box-shadow: 2px 1px 5px 1px rgba(163, 163, 163, 0.3);
        border-radius: 6px;

        .top {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 40px;

          .avatar {
            max-width: 150px;
            width: 100%;
            height: auto;
          }

          .name {
            font-size: 26px;
            line-height: 32px;
            font-weight: 600;
            margin-left: 20px;
            flex: 0 0 calc(100% - 170px);
          }
        }

        .desc {
          p {
            font-size: 16px;
            font-weight: 500;

            @media (min-width: 992px) {
              font-size: 18px;
            }

            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
        .partners {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 15px;
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 0;
          li {
            flex: 1;
            img {
              height: 60px;
              width: auto;
            }
          }
        }
      }
    }
  }
}
