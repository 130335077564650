.adviseryBoard {
  padding: 50px 0;
  .containerBig {
    max-width: 1600px;
  }
  .headline {
    h2 {
      color: #000000;
      font-family: "seguibl";
      text-align: center;
      text-transform: uppercase;
      font-size: 25px;
      line-height: 35px;
      margin: 0 auto 20px;
      @media (min-width: 992px) {
        font-size: 40px;
        line-height: 40px;
        margin: 0 auto 30px;
      }
      @media (min-width: 1200px) {
        font-size: 70px;
        line-height: 80px;
        margin: 0 auto 50px;
      }
      span {
        background: #005d92;
        background: -webkit-linear-gradient(to right, #005d92 0%, #38b6ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    h4 {
      font-size: 16px;
      line-height: 22px;
      @media (min-width: 992px) {
        font-size: 22px;
        line-height: 28px;
      }
      @media (min-width: 1200px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      padding: 15px;
      flex: 0 0 100%;
      width: 100%;
      height: 100%;
      @media (min-width: 576px) {
        flex: 0 0 50%;
        width: 50%;
      }
      @media (min-width: 768px) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
      }
      @media (min-width: 992px) {
        flex: 0 0 25%;
        width: 25%;
      }
      @media (min-width: 1600px) {
        flex: 0 0 20%;
        width: 20%;
      }
      .main {
        border: 1px solid #e3e3e3;
        border-radius: 8px 8px 21px 21px;
        .pic {
          width: 100%;
          height: auto;
          @media (min-width: 576px) {
            height: 300px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
            border-radius: 8px 8px 0 0;
          }
        }
        .content {
          text-align: center;
          .title {
            color: #000000;
            font-family: "Segoe UI Bold";
            padding: 10px 10px 0;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 22px;
            width: 100%;
          }
          .position {
            width: 100%;
            color: #000000;
            font-family: "Segoe UI Italic";
            margin: auto;
            font-size: 14px;
            line-height: 20px;
            padding: 10px;
            @media (min-width: 576px) {
              min-height: 70px;
            }
          }
        }
      }
    }
  }
}
