.trainCourseFaq {
  padding: 30px 0;
  background-color: #f9f9f9;
  @media (min-width: 768px) {
    padding: 50px 0;
  }
  div[id~="accordion"] {
    max-width: 750px;
    margin: auto;
    .card {
      border: 0;
      background-color: transparent;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .cardHeader {
        h5 {
          button {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            background: linear-gradient(
              to right,
              #38c1cf 0%,
              #5bcbb8 50%,
              #005d92 50.1%,
              #38b6ff 100%
            );
            background-size: 200%;
            border: 0;
            padding: 10px;
            border-radius: 4px;
            background-position: 0 0;
            transition: 0.3s ease all;
            span {
              color: #fff;
            }
            &:focus {
              outline: 0;
              box-shadow: none;
            }
            .chText {
              flex: 0 0 calc(100% - 40px);
              margin-right: 16px;
            }
            svg {
              flex: 0 0 24px;
              path {
                fill: #fff;
              }
            }
          }
          button[class~="collapsed"] {
            background-position: -100% 0;
          }
        }
      }
  
      .tcCollapse {
        height: 0;
        transition: 0.3s ease all;
        .cardBody {
          margin-top: 10px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 6px 2px rgba(56, 193, 207, 0.1);
          p {
            margin-bottom: 0;
          }
        }
      }
      div[class~="show"] {
        height: auto;
      }
    }
  }
}

div[id~="main-accordion"] {
  .card {
    border: 0;
    background-color: transparent;
    .cardHeader {
      background-color: transparent;
      border-bottom: 0;
      .maTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        border: 0;
        background-color: transparent;
        .heading {
          font-size: 32px;
          line-height: 38px;
          font-family: "seguibl";
          text-transform: uppercase;
          display: table;
          margin: 0 auto;
          @media (min-width: 768px) {
            font-size: 40px;
            line-height: 46px;
          }
          @media (min-width: 992px) {
            font-size: 55px;
            line-height: 61px;
          }
        }
        svg {
          width: 26px;
          height: 26px;
          @media (min-width: 768px) {
            width: 32px;
            height: 32px;
          }
          @media (min-width: 992px) {
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }
}
