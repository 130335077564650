div[class~='fd-modal__dialog'] {
  max-width: 450px !important;

  div[class~='fd-modal__content'] {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border-radius: 8px !important;

    div[class~='fd-modal__body'] {
      form {
        padding: 10px 20px 20px !important;

        div[class~='ff-65ba9b2a0f1ef3462a419ea6__title'] {
          font-size: 28px !important;
          line-height: 32px;
          margin: 0 0 5px 0;
        }

        div[class~='ff-65ba9b2a0f1ef3462a419ea6__subtitle'] {
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 10px 0;
          font-weight: 400;
        }

        div[class~='fd-form-group'] {
          margin-bottom: 8px;

          input {
            height: auto !important;
            padding: 6px 10px !important;
            border-radius: 4px !important;
            color: #000;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
          }

          label {
            top: 50% !important;
            transform: translateY(-50%);
            padding: 0 15px !important;
          }
        }

        button {
          padding: 6px 20px !important;
          border-radius: 4px !important;
        }
      }
    }
  }
}