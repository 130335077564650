@import "src/assets/css/helpers/variables.scss";

.courseComponentsSection {
  padding: 50px 0;
  .sectionTitle {
    text-align: center;
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 30px;
    font-weight: 900;
    font-family: "Segoe UI Bold";
  }
  .sectionDescription {
    margin-bottom: 30px;
    font-size: unquote("clamp(1rem, 0.75rem + 1.25vw, 2.25rem)");
    font-weight: 500;
    text-align: center;
  }
  .courseComponents {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    list-style-type: none;
    margin-bottom: 50px;
    li {
      img {
        max-width: 50px;
        width: 100%;
        margin-right: 15px;
      }
      span {
        font-size: 18px;
        font-weight: 500;
      }
      &:not(:last-child) {
        border-right: 1px solid $paragraph;
        padding-right: 15px;
      }
    }
  }
  .desc {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }
}
