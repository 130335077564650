@import 'src/assets/css/helpers/variables.scss';

.schoolBox {
  border: 3px dashed #346e8a;
  box-shadow: 0 0 25px -2px #dedede;
  padding: 40px;
  @media screen and (max-width: 767px) {
    padding: 40px 15px;
  }
  .visualText {
    h3 {
      font-size: 50px;
      font-weight: 700;
      color: $brand;
      @media screen and (max-width: 767px) {
        font-size: 30px !important;
        text-align: center;
      }
    }
    p {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .sbButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column wrap;
      gap: 15px 0;
      @media (min-width: 1400px) {
        flex-flow: row wrap;
        gap: 0 15px;
        justify-content: flex-start;
      }
      .link {
        padding: 10px;
        width: 100%;
        font-weight: 700;
        line-height: 40px;
        border-radius: 0px;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;
        max-width: 300px;
        width: 100%;
        @media (min-width: 767px) {
          font-size: 20px;
        }
        @media (min-width: 1199px) {
          font-size: 16px;
        }
        @media (min-width: 1200px) {
          font-size: 18px;
        }
        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }
  }
}

.schoolPartner {
  .containerBig {
    max-width: 1660px;
  }
  .spLeft {
    max-width: 380px;
    margin: auto;
    text-align: center;
    p {
      font-size: 20px;
      font-weight: 500;
    }
  }
  img {
    width: 100%;
    display: block;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
}
