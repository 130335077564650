@import 'src/assets/css/helpers/variables.scss';

.slider {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  div[class~='slick-list'] {
    order: 1;
    width: 100%;
    overflow: hidden;

    @media (min-width: 992px) {
      order: 2;
      width: calc(100% - 144px);
    }

    div[class~='slick-track'] {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      div[class~='slick-slide'] {
        padding: 0 20px;
      }
    }
  }

  div[class~='slick-arrow'] {
    width: 72px;
    position: relative;

    &[class~='slick-prev'] {
      order: 2;

      @media (min-width: 992px) {
        order: 1;
      }
    }

    &[class~='slick-next'] {
      order: 3;

      @media (min-width: 992px) {
        order: 2;
      }
    }
  }
}