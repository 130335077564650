@import 'src/assets/css/helpers/variables.scss';

.subscribe {
  background-color: $gray-2;
  padding: 100px 0;
  &__title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0;
    color: $brand;
    @media (min-width: 767px) {
      font-size: 36px;
    }
  }
  &__desc {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }
  &__form {
    form {
      padding: 0 !important;
      [class*='title'],
      [class*='subtitle'] {
        display: none !important;
      }
      [class~='fd-form-content'] {
        max-width: 300px !important;
        width: 100%;
        margin: 0 auto !important;
        [class*='fields'] {
          display: flex;
          flex-flow: column wrap;
          flex: 0 0 100%;
          gap: 15px;
          [class~='fd-form-group'] {
            margin: 0;
            max-width: 100% !important;
            input {
              background-color: #fff !important;
              border-radius: 4px;
            }
          }
        }
        [class*='footer'] {
          flex: 0 0 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
          margin-top: 15px !important;
          button {
            width: 100% !important;
            background-color: $brand;
            border-color: $brand;
            border-radius: 4px;
          }
        }
      }
    }
  }
}