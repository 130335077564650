@import "src/assets/css/helpers/animation.scss";

.futureLeader {
  animation: bgGradient 15s ease infinite;
  background: linear-gradient(-45deg, #005d92, #38b6ff, #38c1cf, #5bcbb8);
  background-size: 400% 400%;
  p {
    margin-bottom: 0;
    color: #fff;
    font-size: unquote("clamp(1rem, 0.75rem + 1.25vw, 2.25rem)");
    font-weight: 500;
    text-align: center;
    padding: 30px 0;
    span {
      font-weight: 900;
      font-style: italic;
    }
  }
}
