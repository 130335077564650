@import "src/assets/css/helpers/variables.scss";

.ourFounderBlock {
  margin-bottom: 80px;
  .founderVerticalText {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      position: absolute;
      top: 44px;
      left: -7%;
      width: 65px;
    }
  }

  .founderPhoto {
    width: 80%;
    border: 5px solid $gold;
    padding: 5px;
  }

  .ourFounderContent {
    .heading {
      color: #50759d;
      font-size: 40px;
      line-height: 1;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
