@import "src/assets/css/helpers/variables.scss";

.testimony {
  .overlay {
    background-color: $brand-light;
  }
}
.icon {
  margin-bottom: 20px;
  svg {
    width: 50px;
    height: 50px;
    path,
    rect {
      fill: $white;
    }
  }
}

.sectionDisclaimer {
  margin: 50px 0;
  .disclaimer {
    color: #818181;
  }
}