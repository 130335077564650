.homeBanner {
  position: relative;
  padding: 215px 0px 100px;
  margin-top: -165px;
  background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);

  .particlesArea>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .col-md-6 {

    &.text-right,
    &.text-left {
      @media screen and (max-width: 767px) {
        text-align: center !important;
      }
    }
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;

    .heading-small {
      font-weight: 500;
    }

    @media (min-width: 767px) {
      font-size: 36px;

      .heading-small {
        font-size: 28px;
      }
    }

    @media (min-width: 992px) {
      font-size: 42px;

      .heading-small {
        font-size: 36px;
      }
    }

    @media (min-width: 1199px) {
      font-size: 50px;

      .heading-small {
        font-size: 46px;
      }
    }
  }

  p {
    font-size: 22px;
    line-height: 30px;
    color: #232323;
    margin-bottom: 30px;
    font-weight: 500;

    @media screen and (max-width: 991px) {
      font-size: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }

    .anim_img {
      img {
        width: auto;
        max-width: 100%;
        display: block;
      }
    }

    .bannerDisclaimer {
      font-size: 15px;
      line-height: 21px;
    }
  }

  .buttonsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;

    a {
      min-width: 130px;
      max-width: 240px;
      width: 100%;
      line-height: 40px;
      outline: none;
      border: none;
      color: #fff;
      text-transform: uppercase;
      border-radius: 0px;
      font-weight: bold;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .anim_img {
    @media (max-width: 991px) {
      margin-top: 50px;
    }
  }
}

.student_programs {
  img {
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .col-md-6.pr-0 {
    @media screen and (max-width: 767px) {
      padding-right: 15px !important;
    }
  }
}