@import 'src/assets/css/helpers/variables.scss';

.news {
  .featuredPost {
    padding-top: 50px;
    position: relative;

    div[class~='slick-list'] {
      padding: 50px !important;

      div[class~='slick-track'] {
        div[class~='slick-slide'] {
          .featuredPostItem {
            padding: 30px 20px;
            filter: grayscale(0.5);
            opacity: 0.5;
            box-shadow: 0px 0px 6px 2px rgba(163, 163, 163, 0.3);
            border-radius: 6px;
            transform: scale(0.9);
            pointer-events: none;
            transition: 0.3s ease all;

            .img {
              position: relative;
              height: 0;
              padding-top: 56.25%;
              margin-bottom: 20px;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }

            .desc {
              h4 {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                min-height: 48px;
              }

              p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                min-height: 42px;
              }
            }
          }

          &+div[class*='slick-center slick-current'] {
            .featuredPostItem {
              filter: grayscale(0);
              opacity: 1;
              z-index: 9999;
              transform: scale(1.05);
              pointer-events: initial;
            }
          }
        }
      }
    }
  }
}