@import 'src/assets/css/helpers/variables.scss';

.pastPrograms {
  margin-bottom: 30px;
  .cardHeader {
    padding: 0;
    button {
      padding: 10px;
      width: 100%;
      background: $brand;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      svg {
        transform: rotate(0deg);
        width: 40px;
        height: 40px;
        transition: 0.3s ease all;
        path {
          fill: #fff;
        }
      }
      &[aria-expanded='true'] {
        svg {
          transform: rotate(180deg);
        }
      }
      outline: 0;
      box-shadow: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .card-body {
    background-color: rgba(235, 235, 235, 0.1);
  }
  .ppWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .ppImg {
      max-width: 215px;
      width: 100%;
      @media (max-width: 768px) {
        margin: auto;
      }
    }
    .ppCont {
      margin-left: 20px;
      max-width: calc(100% - 235px);
      @media (max-width: 768px) {
        max-width: 100%;
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
      }
      .ppcTitle {
        text-transform: capitalize;
        font-weight: 600;
      }
    }
    &:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #c1c1c1;
    }
  }
}
