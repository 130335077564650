@import 'src/assets/css/helpers/variables.scss';

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: $bg-gray;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 39, 63, 0.7);
  }

  .content {
    position: relative;
    color: $brand;
    padding: 25px 0;
    margin: auto;
    text-align: center;
    z-index: 1;

    @media (min-width: 992px) {
      padding: 100px 0;
    }

    &.hasOverlay {
      color: $white;

      .subHeading {
        border-color: $white;
      }
    }

    .mainHeading {
      font-size: 36px;
      line-height: 42px;

      @media (min-width: 768px) {
        font-size: 50px;
        line-height: 56px;
      }

      margin-bottom: 30px;
      font-weight: 900;
      font-family: 'Segoe UI Bold';
      color: $white;
      span[class~='ds-research-title'] {
        @media (min-width: 768px) {
          font-size: 58px;
          line-height: 64px;
        }
      }
      i {
        font-size: 26px;
        line-height: 32px;

        @media (min-width: 768px) {
          font-size: 40px;
          line-height: 46px;
        }

        font-weight: 500;
        font-style: italic;
      }
      span[class~='nhsrp-title-small'] {
        font-family: 'Segoe UI Regular';
        font-size: 26px;
        line-height: 32px;
        font-weight: 300;
      }
    }

    .mentorsLogo {
      padding: 0;
      margin: 0 0 30px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style-type: none;
      gap: 15px;
      img {
        max-width: 100px;
        width: auto;
        height: auto;
      }
      svg {
        @media (max-width: 991.99px) {
          height: 60px;
        }
      }
    }

    .subHeading {
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid $brand;
      border-radius: 16px;
      position: relative;
      color: $white;

      @media (min-width: 992px) {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 30px;
      }
    }

    .description {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 30px;

      @media (min-width: 992px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      width: auto;

      a {
        width: 100%;
        border-radius: 100px;
        font-size: 24px;
        line-height: 30px;
        padding: 20px;
      }
    }
  }
}
