@import "src/assets/css/helpers/variables.scss";

.programBenefits {
  padding: 50px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  div[class~="container"] {
    position: relative;
    z-index: 1;
  }
  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    color: $white;
    margin-bottom: 50px;
  }
  .keypoints {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
    li {
      font-size: 18px;
      color: $white;
      flex: 0 0 calc(100% - 10px);
      @media (min-width: 768px) {
        flex: 0 0 calc(33.333% - 10px);
      }
      &:before {
        content: "\2713";
        font-weight: 600;
        margin-right: 15px;
        color: $gold;
      }
    }
  }
  .desc {
    font-size: 18px;
    color: $white;
    margin: 0 auto;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    @media (min-width: 1200px) {
      max-width: 980px;
    }
  }
}
