@import "src/assets/css/helpers/variables.scss";

.studentsTestimonials {
  padding: 50px 0 0;

  .sectionInfo {
    color: $brand-light;
    margin-bottom: 30px;
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
      padding-left: 100px;
    }

    .title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .sliderWrap {
    .desc {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
      text-align: center;

      @media (min-width: 992px) {
        padding-left: 100px;
        text-align: left;
      }
    }

    .testimonialSlider {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 0 15px;

      div[class~="slick-list"] {
        order: 1;
        width: 100%;
        overflow: hidden;

        @media (min-width: 768px) {
          width: calc(100% - 174px);
          order: 2;
        }

        div[class~="slick-track"] {
          display: flex;

          div[class~="slick-slide"] {
            padding: 10px;
            overflow: hidden;

            .testimonyWrap {
              background-color: $white;
              border-radius: 8px;
              padding: 20px;
              position: relative;
              border: 1px solid $gold;

              &::before {
                content: "";
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(-45deg, #38c1cf 0%, #5bcbb8 100%);
                transform: translate3d(14px, 8px, 0px) scale(0.95);
                filter: blur(7px);
                opacity: 0.4;
                transition: opacity 0.3s;
                border-radius: inherit;
              }

              .testimony {
                font-size: 16px;
                font-weight: 500;
              }

              .testifier {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                font-style: italic;
              }
            }
          }
        }

      }

      div[class~="slick-arrow"] {
        position: relative;
        left: auto;
        right: auto;
        width: 72px;
        height: 42px;
        display: inline-flex;

        &:before {
          content: none;
        }
      }

      div[class~="slick-prev"] {
        order: 2;

        @media (min-width: 768px) {
          order: 1;
        }
      }

      div[class~="slick-next"] {
        order: 3;

        .arrow {
          margin-left: auto;
        }
      }
    }
  }
}