@import "src/assets/css/helpers/variables.scss";
@import "src/assets/css/helpers/animation.scss";

.createdBy {
  padding: 30px 0;
  .wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .desc {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;
      flex: 0 0 100%;
      margin-bottom: 20px;
      text-align: center;
      color: $white;
    }
    .logos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style-type: none;
      gap: 0 20px;
      flex: 0 0 348px;
      img {
        height: 100px;
        width: auto;
      }
      &.multipleLogos {
        flex: 0 0 100%;
        justify-content: center;
        gap: 30px;
        img {
          height: 50px;
        }
      }
    }
  }
}
