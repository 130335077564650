@import "src/assets/css/helpers/variables.scss";

.trainOfferings {
  padding: 50px 0;
  .wrap {
    border: 3px solid $blue;
    padding: 30px;
    border-radius: 8px;
    .content {
      text-align: center;
      img {
        background-color: $blue;
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 8px;
      }
      .title {
        color: $blue;
        margin-bottom: 0;
      }
    }
  }
}
