.whatStudentsLearn {
  .keyDetailsDesc {
    font-size: 16px;
  }
  .keyTopicsList {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 0 0 calc(50% - 20px);
      margin: 10px;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .icon {
        display: flex;
        margin-right: 10px;
        margin-top: 6px;
        background: #005d92;
        background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
